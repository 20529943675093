import { DiagramLocatorLocator } from './../../../base/diagram/locator/diagram-locator-locator';
import { EDataLocatorLocator } from './../../../base/edata/locator/edata-locator-locator';
import { cloneDeep, isEqual, isEmpty } from 'lodash';
import { take, tap, switchMap } from 'rxjs/operators';
import { DefinitionLocator } from './../../../base/shape/definition/definition-locator.svc';
import { Component, ChangeDetectionStrategy, Input, OnDestroy } from '@angular/core';
import { merge, of, Subscription } from 'rxjs';
import { IDataSource, IDataSourceModel } from './model/data-source.model';

@Component({
    template: `
        <div class="data-source-card" draggable="true" (dragstart)="handleDragStart( $event )">
            {{data?.dataSourceId}} {{data?.title}}
        </div>
    `,
    selector: 'data-source-card-item',
    styleUrls: [ './data-source-card-item.cmp.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataSourceItemCard implements OnDestroy {

    /**
     * data source model data getting from the source.
     */
    @Input()
    public data: IDataSourceModel;

    /**
     * The definition id of the datasource EDATA model.
     */
    @Input()
    protected eDefId: string;

    /**
     * The definition id of the datasource SHAPE.
     */
    @Input()
    protected defId: string;

    /**
     * version of the SHAPE definition.
     */
    @Input()
    protected version: number;

    /**
     * Holds the currentLibraries state subscription.
     */
    protected subs: Subscription[];

    constructor(
        protected defLocator: DefinitionLocator,
        private el: EDataLocatorLocator,
        protected ll: DiagramLocatorLocator,
    ) {
        this.subs = [];
    }

    /*
    * Handle the drag start event on the library tile
    * Hide popover preview if it is open
    * @param event
    * @param data
    */
    public handleDragStart( event: any ) {
        const sub1 = this.ll.forCurrentObserver( false ).pipe(
            take( 1 ),
            switchMap( locator => locator.getDiagramEData().pipe(
                switchMap( eDataList => {
                    if ( !eDataList ) {
                        return of([]);
                    }
                    const eSubs = [];
                    eDataList.forEach( eId => {
                        const eSub = this.el.getEData( eId ).pipe(
                            switchMap( eLocator => eLocator.getEDataModelOnce()));
                        eSubs.push( eSub );
                    });
                    return merge( ...eSubs );
                }),
            )),
        ).subscribe();

        const sub2 = this.el.currentEDataModels().pipe(
            take( 1 ),
            switchMap( eDataModels => {
                const gitEdata = ( eDataModels || []).find( ed => ed.defId === this.eDefId );
                const dataSource = this.getDataSource();
                let entity;
                if ( gitEdata && gitEdata.entities ) {
                    entity = Object.keys( gitEdata.entities )
                        .map( key => gitEdata.entities[ key ])
                        .find( e => isEqual( e.dataSource, dataSource ));
                }
                return this.defLocator.getDefinition( this.defId, this.version ).pipe(
                    take( 1 ),
                    tap(( shapeData: any ) =>  {
                        shapeData = cloneDeep( shapeData );
                        shapeData.name = this.data.title;
                        shapeData.triggerNewEData = true;
                        if ( shapeData && !shapeData.data || isEmpty( shapeData.data )) {
                            shapeData.data = {};
                        }
                        Object.assign( shapeData.data, shapeData.dataDef );
                        shapeData.data.number.value = this.data.dataSourceId.toString();
                        shapeData.data.title.value = this.data.title.toString();
                        shapeData.data.description.value = this.data.description;
                        shapeData.data.tags.value = this.data.tags.filter( tag => tag.name !== null );
                        shapeData.data.people.value = {
                            source: this.data.people.source,
                            people: this.data.people.people.filter ( p => p.fullName !== null && p.id !== null )},
                        shapeData.data.commentCount.value = 0;
                        shapeData.dataSource = dataSource;
                        if ( entity ) {
                            shapeData.eData = {
                                [gitEdata.id]: entity.id,
                            };
                        }
                        event.dataTransfer.effectAllowed = 'move';
                        event.dataTransfer.setData( 'Text', JSON.stringify( shapeData ));
                    }),
                );
            }),
        ).subscribe();
        this.subs.push( sub1 );
        this.subs.push( sub2 );
    }

    /**
     * Unsubscribes from all subscriptions.
     */
    public ngOnDestroy(): void {
        this.subs.forEach( sub => {
            sub.unsubscribe();
        });
        this.subs = [];
    }


    protected getDataSource(): IDataSource {
        this.data.dataSource.data.dataSourceId = this.data.dataSourceId ;
        return this.data.dataSource;
    }

}
