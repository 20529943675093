import { Observable, defer, concat } from 'rxjs';
import { ignoreElements, mapTo } from 'rxjs/operators';
import { Proxied } from '@creately/sakota';
import { MapOf, CommandScenario, Logger } from 'flux-core';
import { DiagramModel } from '../../../base/diagram/model/diagram.mdl';
import { IChangeBinder } from './change-binder.i';
import { Injectable } from '@angular/core';

/**
 * Change binding service keeps a registry of change binders.
 * The apply method on change binding service can be used to
 * run all change binders which are relevant to the change.
 */
@Injectable()
export class ChangeBindingService {
    /**
     * A map of registered change binders identified by their names.
     */
    protected binders: MapOf<IChangeBinder>;

    /**
     * Initialize!
     */
    constructor() {
        this.binders = {};
    }

    /**
     * Registers a change binder.
     * @param binder The binder instance.
     */
    public register( binder: IChangeBinder ): void {
        this.binders[binder.name] = binder;
    }

    /**
     * Executes all relavent change binders.
     * @param diagram The proxied diagram model.
     */
    public apply( diagram: Proxied<DiagramModel>, cs?: CommandScenario,
                  ctx: any = {}): Observable<Proxied<DiagramModel>> {
        /**
         * FIXME This is an incomplete implementation of applying change bindings.
         *       Refer to the design and implement missing features as needed.
         */


        // FIXME - ShapeCodeBinder should be the final binder to run accrding to the
        // change binder framework which is yet to be completed.
        // For the moment, TextBoundsBinder was added twice because some properties are updated in
        // ShapeCodeBinder and those are needed in TextBoundsBinder.
        // https://paper.dropbox.com/doc/Change-Binders-Implementation-80LOZmDZSkCN55kBX8Szy
        const binders = this.getBindersList();
        // const changes = diagram.__sakota__.getChanges();
        // const binders = this.getMatchingBinders( changes );
        // if ( !binders.length ) {
        //     return of( diagram );
        // }

        return concat( ...binders.map( binder => defer(() => {
            try {
                return binder.apply( diagram, cs, ctx );
            } catch ( e ) {
                Logger.error( 'Error thrown in ', binder.name, e  );
            }
        }))).pipe(
            ignoreElements(),
            mapTo( diagram ),
        );
    }


    protected getBindersList() {
        return [
            this.binders.ConnectorLookupValueBinder,
            this.binders.TextDataItemBinder,
            this.binders.TextBoundsBinder,
            this.binders.ShapeBoundsBinder,
            this.binders.ContainerChangeBinder,
            this.binders.GluepointsBinder,
            this.binders.ContainerChildrenLayoutingBinder,
            this.binders.ConnectorReconnectBinder,
            this.binders.TextParserBinder,
            this.binders.DataDefCodeBinder,
            this.binders.ContainerChildrenLayoutingBinder,
            this.binders.ConnectorReconnectBinder,
            this.binders.ConnectorReconnectBinderAutolayout,
            this.binders.ShapeBoundsBinder,
            this.binders.ShapeAddedBinder,
            this.binders.TaskBinder,
            // this.binders.ShapeEDataChangeBinder,
            this.binders.ContainerChildrenLayoutingBinder,
            this.binders.TextDataItemBinder,
            this.binders.ShapeCodeBinder,
            this.binders.TableCellsResizeBinder,
            this.binders.ContainerChangeBinder,
            this.binders.ConnectorReconnectBinder,
            this.binders.TextBoundsBinder,
            this.binders.ConnectionChangeBinder,
            this.binders.ContainerRulesBinder,
            this.binders.DisplayRuleBinder,
            this.binders.ShapeAddedSanitizeBinder,
            this.binders.SanitizeBinder,
        ];
    }

    /**
     * Returns an array of change binders relevant to the change.
     * @param changes Changes done to the diagram.
     */
    // private getMatchingBinders( changes: Partial<Changes> ): IChangeBinder[] {
    //     const binders = [];

    //     for ( const key in this.binders ) {
    //         const binder = this.binders[key];
    //         if ( binder.paths.indexOf( '*' ) !== -1 ) {
    //             binders.push( binder );
    //             continue;
    //         }
    //         const pattern = binder.paths.join( '|' );
    //         if ( this.hasShapeChanges( changes, pattern )) {
    //             binders.push( binder );
    //         }
    //     }
    //     return binders;
    // }

    /**
     * Checks whether the given modifier changes shapes which matches given pattern.
     * @param modifier The modifier to check for changes.
     */
    // private hasShapeChanges( modifier: Partial<Changes>, pattern: string ): boolean {
    //     const regex = new RegExp( `shapes\\.[^\\.]+\\.(?:${ pattern })`, 'g' );
    //     for ( const opname in modifier ) {
    //         const opdata = modifier[opname];
    //         for ( const key in opdata ) {
    //             if ( regex.test( key )) {
    //                 return true;
    //             }
    //         }
    //     }
    //     return false;
    // }
}
