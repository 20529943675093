<div class="datasource-panel-container">
    <div class="container">
        <div class="row">
        <mat-expansion-panel #searchForm hideToggle class="git-search-expansion-panel" [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title class="git-search-title body body--bold">
                    Search on Github
                </mat-panel-title>
                <mat-panel-description class="git-search-description caption">
                    <mat-icon>swap_vert</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="justify-content-md-center" *ngIf="(isLoading | async)  === true">
                <loading-bubbles class="fx-center" color="xmedium-grey"></loading-bubbles>
            </div>
            <div class="justify-content-md-center git-source-auth" tooltip-tour-step="data-5"
                *ngIf="(isAuthenticated | async) === false  && (isLoading | async)  === false">
                <div class="git-source-auth-button">
                    <div class="body" translate>
                        DATA_SOURCE.GITHUB.MAIN_DESC
                    </div>
                    <div class="git-source-auth-description caption">
                        <span translate>DATA_SOURCE.GITHUB.AUTH_DESC</span>
                        <br />
                        <span translate>DATA_SOURCE.GITHUB.TO_CONNECT</span>
                    </div>
                    <button class="auth-btn btn-primary fx-span-width fx-no-padding fx-no-margin"
                        (click)="authenticate()">Connect to Github</button>
                </div>
            </div>
            <div class="justify-content-md-center"
                *ngIf="(isAuthenticated | async) === true && (isLoading | async)  === false">
                <div>
                    <div class="enitiy-search-item-type"
                        *ngIf="(isLoading | async)  === false && !issues && (isErrorOccur | async)  === true">
                        <div class="main-error-title fx-flex" translate>
                            <svg class="nu-icon nu-ic-warning-solid nu-ic-error">
                                <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-warning-solid"></use>
                            </svg>
                            DATA_SOURCE.GITHUB.BASIC_ERROR
                        </div>
                        <button class="auth-btn nu-btn-med primary fx-span-width fx-no-padding fx-no-margin"
                            (click)="forceReload()">Try Again</button>
                    </div>
                    <div  *ngIf="( ownerRepos | async ) as items">
                        <form [formGroup]="form" (ngSubmit)="onSubmit()" class="form-data-source" >
                            <!-- <form class="container" [formGroup]="form" (ngSubmit)="onSubmit()" > -->
                            <mat-form-field class="example-full-width" appearance="standard" [floatLabel]="'always'">
                                <mat-label class="caption mat-label-color">Owner</mat-label>
                                <input class="caption mat-input-color" type="text" matInput [formControl]="ownersFormControl" [matAutocomplete]="auto">
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="ownerSelected($event)">
                                    <mat-option *ngFor="let option of filteredOwners | async" [value]="option">
                                    {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
            
                            <mat-form-field class="example-full-width" appearance="standard" [floatLabel]="'always'">
                                <mat-label class="caption mat-label-color">Repo</mat-label>
                                <input class="caption mat-input-color" type="text" matInput [formControl]="reposFormControl" [matAutocomplete]="auto2">
                                <mat-autocomplete #auto2="matAutocomplete">
                                    <mat-option *ngFor="let option of filteredRepos | async" [value]="option">
                                    {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        
                            <mat-form-field class="example-full-width" appearance="standard" [floatLabel]="'always'">
                                <mat-label class="caption mat-label-color">Get</mat-label>
                                <select class="caption mat-input-color" formControlName="type" matNativeControl required>
                                <option *ngFor="let dsType of dataSourceTypeList" value="{{dsType.value}}">{{dsType.label}}</option>
                                </select>
                            </mat-form-field>
            
                            <mat-form-field class="example-full-width" appearance="standard" [floatLabel]="'always'">
                                <mat-label class="caption mat-label-color">Filter</mat-label>
                                <input class="caption mat-input-color" formControlName="filter" matInput placeholder="Ex. label:bug">
                            </mat-form-field>
                            <loading-bubbles *ngIf="(fetching | async) === true" class="fx-center" color="xmedium-grey"></loading-bubbles>
                        </form>
                    </div>
                </div>
            </div>
            <div>
                <div class="align-self-center button-raw">
                    <div class="git-source-filter-list-container"
                        *ngIf="(isAuthenticated | async) === true && issues && (isErrorOccur | async)  === false ">
                        <div class="git-source-search-result-title body body--bold">Search Results</div>
                        <span class="git-source-filter-list-container-item body"
                            *ngFor="let item of filterRulesList">{{item}}</span>
                    </div>
                </div>
                <div class="align-self-center button-raw">
                    <div class="search-button-row"
                    *ngIf="(isAuthenticated | async) === true && (isErrorOccur | async)  === false ">
                        <button class="btn-medium btn-primary fx-span-width fx-no-padding fx-margin-right-8"
                            mat-stroked-button [disabled]="!form.valid || (fetching | async) === true"
                            (click)="onSubmit()">Submit</button>
                        <button class="btn-medium btn-secondary fx-span-width fx-no-padding fx-margin-right-8"
                            (click)="onCancel()" mat-stroked-button>Cancel</button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
        </div>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="git-source-list-container"
            *ngIf="(isAuthenticated | async) === true && issues && issues.length === 0 && (isErrorOccur | async)  === false ">
            <div class="git-no-result"></div>
            <div class="git-no-result-text body body--bold">No results found on GitHub</div>
        </div>
        <div class="git-source-list-container"
            *ngIf="(isAuthenticated | async) === true && issues && issues.length === 0 && (isErrorOccur | async)  === true ">
            <div class="main-error-title fx-flex" translate>
                <svg class="nu-icon nu-ic-warning-solid nu-ic-error">
                    <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-warning-solid"></use>
                </svg>
                DATA_SOURCE.GITHUB.BASIC_ERROR
            </div>
        </div>
        <div class="pointer" *ngIf="(isAuthenticated | async) === true && (isErrorOccur | async)  === false">
            <div *ngIf="issues && issues.length > 0" class="search-result-menu">
                <div class="moveble-image">
                    <svg class="nu-icon nu-ic-movable">
                        <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-movable"></use>
                    </svg>
                </div>
                <div class="medium-menu-text no-left-margin">
                    Search Result ( {{issues.length}} )
                </div>
            </div>
            <div class="result-contianer">
                <div #listContainer></div>
            </div>
        </div>
    </div>
</div>