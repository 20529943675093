import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { map, startWith, take, takeUntil } from 'rxjs/operators';
import { DataSourceItemCard } from './../data-source-card-item.cmp';
import { AbstractDataSourceImplementation } from '../model/abstract-dataSource';
import { GithubService } from './github.svc';
import { ICollapsibleMenuItem } from 'flux-core/src/ui';
import { Component, ChangeDetectionStrategy,
    ViewContainerRef, ViewChild, ComponentFactoryResolver, Injector, ComponentRef, OnInit, AfterViewInit } from '@angular/core';
import { ICollasibleItemOption, StateService } from 'flux-core';
import { BehaviorSubject, Observable, forkJoin, Subject } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { DataSourceDomain, GithubDataSourceTypes, IDataSource } from '../model/data-source.model';
import { DefinitionLocator } from '../../../../base/shape/definition/definition-locator.svc';
import { DataSourceCardListItem } from '../data-source-card-item-list.cmp';
import { Converter } from 'showdown';
import { MatExpansionPanel } from '@angular/material/expansion';
/**
 * This is the placeholder component for the edata panel in the
 * left sidebar.
 */

@Component({
    templateUrl: './github-data-source.cmp.html',
    selector: 'github-data-source',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [ './github-data-source.cmp.scss' ],
})

// tslint:disable-next-line: max-line-length
export class GitHubDataSource extends AbstractDataSourceImplementation implements ICollapsibleMenuItem, OnInit, AfterViewInit {

    public static eDataDefId = 'creately.edata.datasource.github';

    /**
     * Name of the icon to use in the application for github datasource.
     */
    public static dataSourceIcon: string = 'github';

    public formData = new BehaviorSubject({});

    public searchResult: Subject<any> = new BehaviorSubject([]);
    public resultCount = 0;


    public filterRulesList: string[] = [];

    @ViewChild( 'listContainer', { read: ViewContainerRef, static: false })
    public listContainer: ViewContainerRef;

    @ViewChild( 'searchForm', { read: MatExpansionPanel, static: false })
    public searchForm: MatExpansionPanel;
    /**
     * A unique id to identify this sidebar panel instance
     */
    public id: string = 'data-sources';

    public title: string  = 'GitHub' ;

    public dataSourceTypeList = [{
        label: 'Issues',
        value: GithubDataSourceTypes.Issue,
    }];

    public subText: BehaviorSubject<any> = new BehaviorSubject( ''  );


    public ownersFormControl: FormControl = new FormControl( '', Validators.required );
    public reposFormControl: FormControl = new FormControl( '', Validators.required );
    public filterFormControl: FormControl = new FormControl( 'is:issue is:open', Validators.pattern( /[a-zA-Z]+:[a-zA-Z]+ ?/ ));

    public filteredOwners: Observable<string[]>;
    public filteredRepos: Observable<string[]>;

    public form = this.formBuilder.group({
        owner: this.ownersFormControl,
        repo: this.reposFormControl,
        type: GithubDataSourceTypes.Issue,
        filter: this.filterFormControl,
    });

    /**
     * Indicate if the user is authenticated to git or not
     */
    public isAuthenticated = new BehaviorSubject( undefined );

    /**
     * Indicate if api information fetching is in progress.
     */
    public isLoading: BehaviorSubject<boolean> = new BehaviorSubject( true );

    /**
     * Indicate if api error occurred
     */
    public isErrorOccur: BehaviorSubject<boolean> = new BehaviorSubject( false );

    /**
     * Indicate if api fetching status
     */
    public fetching: BehaviorSubject<boolean> = new BehaviorSubject( false );

    /**
     * this will holds all the issues fetch by github.
     */
    public issues = null;

    /**
     * this will holds all the repos own by owner.
     */
    public ownerRepos = new BehaviorSubject( null );

    /**
     * data source id
     */
    protected dataSource = DataSourceDomain.Github;

    /**
     * version of the SHAPE definition.
     */
    protected dataSourceDefId = 'creately.card.datasource';

    /**
     * version of the SHAPE definition.
     */
    protected dataSourceDefVersion = 1;

    /**
     * Time to wait till OAuth flow finishes.
     */
    private authWaitTime = 60000; // one minute.

    /**
     * cancel featch request
     * FIX still fetch is happaning but not rendering on screen
     */
    private closed = new Subject<boolean>();

    constructor(
        protected githubSvc: GithubService,
        protected state: StateService<any, any>,
        protected componentFactoryResolver: ComponentFactoryResolver,
        protected injector: Injector,
        private formBuilder: FormBuilder,
        protected defLocator: DefinitionLocator,
    ) {
        super( componentFactoryResolver, injector );
    }

    public ngOnInit() {
        this.cacheTheDataSourceDefinition();
    }

    // tslint:disable-next-line:use-life-cycle-interface
    public ngAfterViewInit() {
        this.authenticated().subscribe(
             isAuthenticated => {
                if ( isAuthenticated === true ) {
                    this.isAuthenticated.next( true );
                    this.doFetchOwnersAndRepos();
                } else {
                    this.isLoading.next( false );
                    this.isAuthenticated.next( false );
                }
            }, () => {
                this.isAuthenticated.next( false );
                this.isLoading.next( false );
            });
    }

    public forceReload() {
        this.doFetchOwnersAndRepos( true );
    }

    public doFetchOwnersAndRepos( forceReload = false ) {
        this.isLoading.next( true );
        this.isErrorOccur.next( false );
        forkJoin({
            repos: this.fetchOwnersAndRepos( forceReload ),
            user: this.getUser( forceReload )},
          ).subscribe({
            next: ({ repos, user }) => {
                const username = user.login;
                this.subText.next( `Signed in as ${username}`  );
                this.isAuthenticated.next( true );
                this.ownerRepos.next( repos );
                const owners = Object.keys( repos );
                this.filteredOwners = this.ownersFormControl.valueChanges
                .pipe(
                  startWith( '' ),
                  map( name => name ? this._filter( owners, name )  : owners ),
                );
            },
            error: () => {
                this.isLoading.next( false );
                this.isErrorOccur.next( true );
            },
            complete: () => {
                this.isLoading.next( false );
                this.isErrorOccur.next( false );
            },
        });
    }

    public ownerSelected( e: MatAutocompleteSelectedEvent ) {
        const ownerRepos = this.ownerRepos.value;
        const repos = ownerRepos[ e.option.value ] || [];

        this.reposFormControl.setValue( null );
        this.filteredRepos = this.reposFormControl.valueChanges
        .pipe(
          startWith( '' ),
          map( name => name ? this._filter( repos, name )  : repos ),
        );
    }

    public onSubmit(): void {
        this.isErrorOccur.next( false );
        const { owner, repo, type , filter } = this.form.value;
        this.filterRulesList = filter.trim().split( ' ' );
        if ( owner && repo && type ) {
            const promise = this.githubSvc.getIssuesOrRequests({
                owner, repo, filter,
                type,
            });
            this.listContainer.clear();
            this.issues = null;
            this.fetching.next( true );
            const md2htmlConverter = new Converter();
            fromPromise( promise )
            .pipe( takeUntil( this.closed ))
            .subscribe( issues =>  {
                const ds = this.getDataSource( owner, repo, type );
                const definedSearchQuery = JSON.stringify({ ...ds, filter });
                this.issues = issues.map( item => {
                    const desc = item.description ? md2htmlConverter.makeHtml( item.description ) : '';
                    item.description =  desc.replace( />\s+</g, '><' );
                    return { ...item , dataSource: ds };
                });
                this.insertThePreDefinedQueryItem( this.issues, DataSourceCardListItem, ds, definedSearchQuery );
                this.insertItems(  this.issues, DataSourceItemCard );
                this.searchForm.close();
                this.fetching.next( false );
            },
            () => {
                this.fetching.next( false );
                this.isErrorOccur.next( true );
                this.issues = [];
            });
        }
    }

    public onCancel() {
        this.closed.next( true );
    }

    public getDataSource( owner: string, repo: string , type: GithubDataSourceTypes ): IDataSource {
        return {
            domain: DataSourceDomain.Github,
            data: {
                type: type,
                owner:  owner,
                repo:  repo,
            },
        };
    }

    public getOwners( ownersRepos: any ) {
        Object.keys( ownersRepos );
    }

    public authenticate() {
        this.isLoading.next( true );
        this.githubSvc.authorize( this.authWaitTime )
            .then(() => {
                this.initCycle();
                this.doFetchOwnersAndRepos();
            })
            .catch(() => {
                this.isLoading.next( false );
                this.isErrorOccur.next( true );
            });
    }

    public getSecondaryMenuItems(): ICollasibleItemOption[] {
     return [
         {
             id: 'github-logout',
             label: 'Logout',
             callback: () => {
                 alert( 'Logout' );
             },
         },
     ];
    }

    public insertThePreDefinedQueryItem ( items: Array<any>, type: any ,
                                          dataSource: IDataSource, definedSearchQuery: string ) {
        const listItemRef: ComponentRef<any> = this.makeComponent( type );
        listItemRef.instance.data = items;
        listItemRef.instance.eDefId = GitHubDataSource.eDataDefId;
        listItemRef.instance.defId = this.dataSourceDefId;
        listItemRef.instance.definedSearchQuery = definedSearchQuery;
        listItemRef.instance.version = this.dataSourceDefVersion;
        listItemRef.instance.dataSource = dataSource;
        this.insert( this.listContainer, listItemRef );
        listItemRef.changeDetectorRef.detectChanges();
    }

    public insertItems( items: Array<any>, type ) {
        items.forEach( item => {
            const itemRef: ComponentRef<any> = this.makeComponent( type );
            itemRef.instance.data = item;
            itemRef.instance.eDefId = GitHubDataSource.eDataDefId;
            itemRef.instance.defId = this.dataSourceDefId;
            itemRef.instance.version = this.dataSourceDefVersion;
            this.insert( this.listContainer, itemRef );
            itemRef.changeDetectorRef.detectChanges();
        });
    }

    protected authenticated() {
        const promise = this.githubSvc.isAuthenticated();
        return fromPromise( promise );
    }

    protected getUser( forceReload = false ) {
        const promise = this.githubSvc.getUser( forceReload );
        return fromPromise( promise );
    }

    protected initCycle() {
        return this.githubSvc.initCycle();
    }

    protected fetchOwnersAndRepos( forceReload = false ): Observable<{ [owner: string]: [] }> {
        const promise = this.githubSvc.fetchOwnersAndRepos( forceReload );
        return fromPromise( promise ).pipe(
            map(( repos: []) => {
                const orgsRepos = {};
                repos.forEach(( r: any ) => {
                    if ( !orgsRepos[ r.owner.login ]) {
                        orgsRepos[ r.owner.login ] = [];
                    }
                    orgsRepos[ r.owner.login ].push( r.name );
                });
                return orgsRepos;
            }),
        );
    }


    protected getComponentType( type: string ) {
        if ( type === 'issue' ) {
            return DataSourceItemCard;
        }
    }

    protected cacheTheDataSourceDefinition() {
        this.defLocator.getDefinition( this.defId, this.version ).pipe(
            take( 1 ),
        ).subscribe();
    }

    private _filter( list, name: string ): string[] {
        const filterValue = name.toLowerCase();
        return list.filter( option => option.toLowerCase().includes( filterValue ));
    }

}

