import { BehaviorSubject, EMPTY, merge, of } from 'rxjs';
import { tap, switchMap } from 'rxjs/operators';
import { ViewContainerRef, ChangeDetectionStrategy, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { DynamicComponent } from '../dynamic.cmp';
import { Observable, Subscription } from 'rxjs';
import { Component, ComponentRef,
    ComponentFactoryResolver, Injector } from '@angular/core';
import { AbstractCollapsibleMenuItem } from './abstract-collapsible-menu-item';
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'collapsible-menu',
    template: `<div class="collapsible-menu">
                    <div class="fx-invisible-item" #componentContainer></div>
                </div>`,
    styleUrls: [ './collapsible-menu.scss' ],
})
/**
 * Collapsible Menu is a component that allows us to generate a menu which has
 * items that can be expanded and collapsed.
 *
 * NOTE: This component needs to be used for cases when there is necessity to dynamically
 * build component otherwise please use "app-collapisble-menu" which is very light weight
 *
 * @author  gobiga
 * @since   2017-11-09
 */
export class CollapsibleMenu extends DynamicComponent implements OnDestroy {

    /**
     * Emits the id of the child removed.
     */
    @Output()
    public childRemoved: EventEmitter<string>;

    /**
     * Emits the id of the child collapsed.
     */
    @Output()
    public childToggled: EventEmitter<string>;

    /**
     * This holds a reference to the component element where the dynamic views can
     * be attached
     */
    @ViewChild( 'componentContainer', { read: ViewContainerRef, static: true })
    protected componentContainer: ViewContainerRef;

    /**
     * This holds all the child shown in collapsible menu
     */
    protected children: Array<ComponentRef<any>>;

    /**
     * This property holds all subscriptions made in the collapsible menu.
     */
    protected subs: Subscription[];

    constructor(
        protected componentFactoryResolver: ComponentFactoryResolver,
        protected injector: Injector,
    ) {
        super( componentFactoryResolver, injector );
        this.children = new Array<ComponentRef<any>>();
        this.childRemoved = new EventEmitter();
        this.childToggled = new EventEmitter();
        this.subs = [];
    }

    /**
     * Returns the menu items.
     */
    public get panels(): Array<ICollapsibleMenuItem> {
        if ( this.children ) {
            return this.children.map( child => child.instance.menuItem );
        }
        return [];
    }

    /**
     * Returns the no of children this component has.
     */
    public get noOfChildren(): number {
        return this.children.length;
    }

    /**
     * Create an instance of menu item and append it to the end of the menu.
     * @param type  The type of the component to create.
     *              This should be a ICollapsibleMenuItem Component class.
     * @param inputs    An object with all the inputs that should be set to the
     *      type component instance. Object keys should have the same name as the input field.
     */
    public add(
        collapsibleMenuItemType: any,
        collapsibleMenuItemContentType: any,
        collapsibleMenuItemContentInput?: { [inputName: string]: any },
        removable: boolean = true,
        collapsibleMenuItemInput?: { [inputName: string]: any },
        index?: number,
    ): Observable<any> {
        // Create the component
        const menuItem: ComponentRef<any> = this.makeComponent( collapsibleMenuItemType );
        const child: AbstractCollapsibleMenuItem = menuItem.instance;
        child.parent = this;
        this.setInputs( child, collapsibleMenuItemInput );

        if ( index && index > this.componentContainer.length ) {
            index = 0;
        }

        const i = index !== undefined ? index : this.componentContainer.length;
        // Adding the component into the children list
        this.children.splice( i, 0, menuItem );

        this.subscribeToToggleChildEvent( menuItem );
        this.subscribeToRemoveChildEvent( menuItem );

        // Add the component with transition ready
        this.insert( this.componentContainer, menuItem, i );
        child.add( collapsibleMenuItemContentType, collapsibleMenuItemContentInput );
        child.removable = removable;
        if ( collapsibleMenuItemContentInput.isCollapsed ) {
            child.close();
        }
        return of( menuItem.instance );
    }

    /**
     * This method will expose the child bounds to other modules.
     * @param childPosition - position of the child in children array
     * @returns a ClientRect
     */
    public getChildBounds( childPosition: number ): ClientRect {
        if ( this.children && this.children.length > 0 && this.children[ childPosition ]) {
            return ( this.children[ childPosition ].location.nativeElement as HTMLElement ).getBoundingClientRect();
        }
    }

    /**
     * Removes the component which is found by the id.
     */
    public detachById( id: string ): Observable<any> {
        const cmp: ComponentRef<any> = this.children.find( child => child.instance.id === id );
        if ( cmp ) {
            return this.detach( cmp );
        }
        return EMPTY;
    }

    /**
     * This returns true if given id is found in the children array.
     */
    public has( id: string ): boolean {
        const component: ComponentRef<any> = this.children.find( child => child.instance.id === id );
        if ( component ) {
            return true;
        }
        return false;
    }

    /**
     * Get given id's menu item from the children.
     */
    public getMenuItemComponent( id: string ): AbstractCollapsibleMenuItem {
        return this.children.find( child => child.instance.id === id ).instance;
    }

    /**
     * This returns true if given id state is collapsed.
     */
    public isActive( id: string ): boolean {
        const component: ComponentRef<any> = this.children.find( child => child.instance.id === id );
        return component?.instance?.active;
    }

    /**
     * Destroys and menu and removes its children.
     * Unsubscribes from all subscriptions made.
     */
    public ngOnDestroy () {
        if ( this.children && this.children.length > 0 ) {
            this.children.forEach( child => {
                super.remove( this.componentContainer, child );
            });
        }
        this.children = [];
        if ( this.subs && this.subs.length > 0 ) {
            this.subs.forEach( sub => sub.unsubscribe());
        }
    }

    /**
     * Removes the component by the menuItem type
     */
     public detachByType( type: any ): Observable<any> {
        const obs = this.children
            .filter( child => child.instance.menuItem instanceof type )
            .map( cmp => this.detach( cmp ));
        return merge( EMPTY, ...obs );
    }

    /**
     * Opens item by menu item id
     */
    public openById( id: string ) {
        const childRef = this.children.find( child => child.instance.menuItem.id === id );
        if ( childRef ) {
            this.open( childRef.instance );
        }
    }

    /**
     * Closes item by menu item id
     */
    public closeById( id: string ) {
        const childRef = this.children.find( child => child.instance.menuItem.id === id );
        if ( childRef ) {
            this.close( childRef.instance );
        }
    }

    // Remove all menu items
    public removeAll() {
        if ( this.children ) {
            this.children.forEach( child => {
                this.remove( this.componentContainer, child ).subscribe();
            });
        }
    }


    /**
     * Sets the input values that are passed in, to the inputs of the component.
     * @param componentInstance - Instance of the component to which the inputs will be set to
     * @param inputs - Object with inputs and their values
     */
    protected setInputs( componentInstance: any, inputs: { [inputName: string]: any }) {
        if ( inputs ) {
            Object.keys( inputs ).forEach( input => {
                componentInstance[input] = inputs[input];
            });
        }
    }

    /**
     * This expands the given menu item.
     */
    protected open( child: AbstractCollapsibleMenuItem ): Observable<any> {
        return child.open();
    }

    /**
     * This removes the given menu item from the collapsible menu.
     */
    protected detach( cmp: ComponentRef<any> ): Observable<any> {
        const index = this.children.indexOf( cmp );

        return this.close( cmp.instance ).pipe(
            tap({
                complete: () => {
                    this.remove( this.componentContainer, cmp );
                    this.children.splice( index, 1 );
                },
            }),
        );
    }

    /**
     * @Override
     * Removes a given component element within a given parent element.
     */
    protected remove( viewContainer: ViewContainerRef, component: ComponentRef<any> ): Observable<any> {
        super.remove( viewContainer, component );
        return EMPTY;
    }

    /**
     * This closes the given menu item.
     */
    protected close( child: AbstractCollapsibleMenuItem ): Observable<any> {
        return child.close();
    }

    /**
     * This adds a subscription for the clickable event on the menu item.
     */
    protected subscribeToToggleChildEvent( componentRef: ComponentRef<any> ) {
        this.subs.push(
            componentRef.instance.toggleChild.pipe(
                switchMap(() => this.toggleChild( componentRef )),
            ).subscribe(),
        );
    }

    /**
     * This subscribes to the removeChild event on the given menu item.
     */
    protected subscribeToRemoveChildEvent( componentRef: ComponentRef<any> ) {
        this.subs.push(
            componentRef.instance.removeChild.pipe(
                tap(() => this.childRemoved.next( componentRef.instance.id )),
                switchMap(() => this.detach( componentRef )),
            ).subscribe(),
        );
    }

    /**
     * This closes other open children if the passed child is
     * set to active
     */
    protected toggleChild( item: ComponentRef<any> ): Observable<any> {
        const child: AbstractCollapsibleMenuItem = item.instance;
        const obs = child.active ? child.close() : child.open();
        this.childToggled.next( item.instance.id );
        return obs;
    }
}

/**
 * Defines the data structure for collapsible menu items.
 */
export interface ICollapsibleMenuItem {
    /**
     * ID of the collapsible menu item
     */
    id: string;

    /**
     * Title of the menuItem
     */
    title: string;

    subText?: Observable<string>;

    /**
     * True of the title of the menuItem is editable
     */
    titleEditable?: Observable<boolean>;

    /**
     * Icon for the menu item.
     */
    icon?: Observable<any>;

    removable?: boolean;

     /**
      * Options for the item. A new
      */
    options?: ICollasibleItemOption[];

    /**
     * Secondary item menu options
     */
    secondaryOptions?: ICollasibleItemOption[];

    /**
     * Parent Collapsible menu item.
     */
    parent?: AbstractCollapsibleMenuItem;

    /**
     * Useful to prevent rendering the component until its expanded
     */
    initialized?: BehaviorSubject<boolean>;

    plusButton?: boolean;

    plusButtonTooltip?: string;

    plusButtonCallback?: Function;

    /**
     * Boolean value to determine is selected item should be highlighted
     */
    highlightSelected?: boolean;

    /**
     * Selected value
     */
    selected?: BehaviorSubject<boolean>;

    /**
     * Floating tooltip title
     */
    tooltipTitle?: string;

    /**
     * Floating tooltip content
     */
    tooltipContent?: string;

    closeTooltip?: Function;
}

export interface ISecondaryMenuItem {
    id: string;
    label: string;
    clickHandler?: Function;
}

export interface ICollasibleItemOption {
    /**
     * To identify the option
     */
    id: string;

    /**
     * Label of the options
     */
    label: string;

    /**
     * Callback method to handle selection
     */
    callback?: Function;

    /**
     * Icon
     */
    icon?: string;

    /**
     * Add seperators for sectioning
     */
    isSeparator?: boolean;

    /**
     * tooltip
     */
    tooltip?: string;

    /**
     * tooltip placement. 'right' | 'top' | 'bottom' | 'left'
     */
    tooltipPlacement?: string;

    /**
     * disabled button layout
     */
    disabled?: boolean;

}
