import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConnectionStatus } from 'flux-connection';
import { AppConfig, CommandService, EventCollector, EventIdentifier, Logger,
    NotifierController, Random, ResourceLoader, StateService } from 'flux-core';
import { IShapeDefinition, ITemplateDefinition, LibraryState, ResourceStatus, ShapeType } from 'flux-definition';
import { DataStore } from 'flux-store';
import * as md5 from 'md5';
import { defer, concat, Observable, EMPTY, from, of } from 'rxjs';
import { catchError, filter, last, map, switchMap, tap } from 'rxjs/operators';
import { ViewportToDiagramCoordinate } from '../../base/coordinate/viewport-to-diagram-coordinate.svc';
import { EntityModel } from '../../base/edata/model/entity.mdl';
import { NotificationMessages, Notifications } from '../../base/notifications/notification-messages';
import { FileImportTypes, ImportedFile } from '../../framework/file/imported-file';
import { DiagramCommandEvent } from '../diagram/command/diagram-command-event';
import { FileReceiver } from '../diagram/import/file-receiver.svc';
import { TemplateModel } from '../diagram/templates/template.mdl';
import { ILoadedLibrary } from '../library/loaded-library.state';
import { cloneDeep as _cloneDeep, difference as _difference, pick } from 'lodash';
import { LibraryType } from '../library/abstract-shape-library';
import { LibraryList } from '../ui/temp-add-libs-menu/library-list';
import { DefinitionLocator } from '../../base/shape/definition/definition-locator.svc';
import { fromPromise } from 'rxjs/internal-compatibility';
import { DiagramToViewportCoordinate } from '../../base/coordinate/diagram-to-viewport-coordinate.svc';
import { DiagramLocatorLocator } from '../../base/diagram/locator/diagram-locator-locator';

const FRAME_TEXT_HEIGHT = 15;

const styleKeys = [
    'size',
    'font',
    'color',
    'bold',
    'italic',
    'underline',
    'strikeout',
    'backgroundColor',
    'align',
    'script',
];

/**
 * This service is to manage executing commands related to the shapes
 *
 * @author @mehdhi
 * @since 2023-07-19
 */
@Injectable()
export class ShapeManageService {

    /**
     * This holds an instance of NotificationMessages which is used to
     * show notifications.
     */
    protected notificationMessages: NotificationMessages;

    constructor(
        protected commandService: CommandService,
        protected state: StateService<any, any>,
        protected vToDcoordinate: ViewportToDiagramCoordinate,
        protected dToVcoordinate: DiagramToViewportCoordinate,
        protected datastore: DataStore,
        protected fileReceiver: FileReceiver,
        protected resourceLoader: ResourceLoader,
        protected notifierController: NotifierController,
        protected translate: TranslateService,
        protected libraryList: LibraryList,
        private defLocator: DefinitionLocator,
        protected ll: DiagramLocatorLocator,
    ) {
        this.notificationMessages = new NotificationMessages( this.translate );
    }

    /**
     * This returns true if the app is offline.
     */
    private get isOffline(): boolean {
        return this.state.get( ConnectionStatus ) === ConnectionStatus.OFFLINE;
    }

    /**
     * Dispatch an addShape command to add the shape into the canvas
     */
    public addShape( def: IShapeDefinition, x: number, y: number, entity?: EntityModel ) {
        EventCollector.log({
            message: EventIdentifier.SHAPE_ADDED,
            def,
            x,
            y,
            entity,
        });
        const shape = {
            id: Random.shapeId(),
            defId: def.defId,
            version: def.version,
            x: this.vToDcoordinate.x( x ),
            y: this.vToDcoordinate.y( y ),
            eData: def.eData,
            data: def.data,
            dataSource: ( def as any ).dataSource,
            triggerNewEData: def.triggerNewEData,
            type: def.type,
            entityDefId: def.entityDefId,
            texts: def.texts,
        } as any;
        if (( def as any ).typeStyle && ( def as any ).typeStyle.bounds ) {
            shape.style = ( def as any ).style;
            shape.scaleX = ( def as any ).scaleX;
            shape.scaleY = ( def as any ).scaleY;
            shape.userSetWidth = ( def as any ).typeStyle.bounds.width;
            shape.userSetHeight = ( def as any ).typeStyle.bounds.height;
            shape.angle = ( def as any ).angle;
            shape.defaultBounds = ( def as any ).defaultBounds;
            shape.shapeContext = ( def as any ).shapeContext;
        }
        const textStyle = ( def as any ).textStyle;
        if ( textStyle ) {
            const textStyles = {};
            for ( const txtId in textStyle ) {
                if ( textStyle[txtId].content ) {
                    textStyles[txtId] = textStyle[txtId].content.map( line  => pick( line, styleKeys ));
                }
            }
            shape.textStyle = textStyles;
        }
        if (( def as any ).drawCode ) {
            shape.instructions = ( def as any ).drawCode.instructions;
            shape.defaultBounds = {
                width: ( def as any ).drawCode.defaultWidth,
                height: ( def as any ).drawCode.defaultHeight,
            };
            shape.scaleX = ( def as any ).drawCode.scaleX;
            shape.scaleY = ( def as any ).drawCode.scaleY;
            shape.style = { lineThickness: ( def as any ).drawCode.lineThickness };
        }
        // Clear undefined values
        Object.keys( shape ).forEach( key => shape[key] === undefined && delete shape[key]);
        let shapeObs = of( shape );
        if ( entity && entity.texts.length > 0 && entity.texts.some( t => t.shapeDef === def.defId )) {
            shapeObs = this.defLocator.getDefinition( shape.defId, shape.version, false ).pipe(
                tap( shapeDef => {
                    const texts = ( shapeDef as any ).texts || {};
                    const primaryTextId = Object.keys( texts ).find( txtId => texts[txtId].primary );
                    if ( primaryTextId ) {
                        const text = entity.texts.find( t => t.shapeDef === def.defId ).text;
                        shape.texts = { [ primaryTextId ] : text };
                    }
                }),
            );
            if ( entity.textStyles ) {
                shape.textStyle = Object.assign( shape.textStyle || {}, entity.textStyles );
            }
        }

        return shapeObs.pipe(
            switchMap(() => this.commandService.dispatch(
                DiagramCommandEvent.addDiagramShape,
                { shapes: { [shape.id]: shape }, entity: entity },
            )),
            last(),
            switchMap(() =>
                this.commandService.dispatch( DiagramCommandEvent.changeContainerData,
                    { shapeIds: [ shape.id ]}),
            ),
            last(),
            map(() => shape ),
            tap(() => {
                // Setting only shapes type of basic, used for recent shapes in quick tools
                if ( def.type === ShapeType.Basic ) {
                    this.state.set( 'LastAddedShape', def.defId );
                }
                if (( def as any ).eDataCandidates && !def.eData ) {
                    this.state.set( 'LastAddedEDataCandidateShape', def.defId );
                }
            }),
        );

    }

    /**
     * Dispatch an addTemplate command to add multiple shapes into the canvas
     */
    public addTemplate( def: ITemplateDefinition, x: number, y: number ) {
        this.state.set( 'DiagramZoomLevel', 1 );
        const templateId = `${def.defId}:${def.version}`;
        EventCollector.log({
            message: EventIdentifier.TEMPLATE_ADDED,
            def,
            x,
            y,
        });

        const diagramId = this.state.get( 'CurrentDiagram' ); // Assuming diagramId is needed as in the original code

        concat(
            this.commandService.dispatch( DiagramCommandEvent.getTemplate, def.diagram, { templateId }),
            defer(() => this.datastore.findOneLatest( TemplateModel, { id: templateId }).pipe(
                switchMap(( tpl: TemplateModel ) => fromPromise( this.defLocator.getTemplateBounds( tpl )).pipe(
                    // Add the line here to get the diagram
                    switchMap(() => this.ll.forDiagram( diagramId, false ).getDiagramOnce().pipe(
                        switchMap(( diagram: any ) => this.defLocator.getDefinition( 'creately.basic.frame', 1 ).pipe(
                            switchMap(( frameDef: any ) => {
                                // Add the libraries
                                if ( tpl && tpl.libraries ) {
                                    this.addLibraries( tpl.libraries );
                                }

                                // Set the template name
                                if ( tpl.name ) {
                                    this.commandService.dispatch( DiagramCommandEvent.setTemplateDef, {
                                        templateId,
                                        templateName: tpl.name,
                                    });
                                    frameDef.texts.main.content[0].text = tpl.name;
                                }

                                // Clone the template at the top-left position
                                const bounds = diagram.getBounds();
                                const clone = tpl.clone({
                                    x: bounds.left + bounds.width + 130,
                                    y: bounds.top + 30,
                                });
                                frameDef.texts.main.content[0].text = tpl.name;

                                return this.addShape( frameDef,
                                    this.dToVcoordinate.x( bounds.left + bounds.width + 100 ),
                                    this.dToVcoordinate.y( bounds.top + FRAME_TEXT_HEIGHT ),
                                ).pipe(
                                    switchMap( frame => this.commandService.dispatch(
                                        DiagramCommandEvent.addDiagramShape, {
                                        cloned: true,
                                        shapes: clone.shapes,
                                        groups: clone.groups,
                                        connections: clone.connections,
                                        dataDefs: clone.dataDefs,
                                        templateData: {
                                            templateId,
                                            context: 'FAB-Panel',
                                        },
                                        frameId: frame.id,
                                    })),
                                );
                            }),
                        )),
                    )),
                )),
            )),
        ).subscribe();
    }

    public addTemplateAsShape( def: ITemplateDefinition, x: number, y: number ) {
        const templateId = `${def.defId}:${def.version}`;
        EventCollector.log({
            message: EventIdentifier.TEMPLATE_ADDED,
            def,
            x,
            y,
        });
        concat(
            this.commandService.dispatch( DiagramCommandEvent.getTemplate, def.diagram, { templateId }),
            defer(() => this.datastore.findOneLatest( TemplateModel, { id: templateId }).pipe(
                switchMap(( tpl: TemplateModel ) => fromPromise( this.defLocator.getTemplateBounds( tpl )).pipe(
                        switchMap(( bounds: any ) => {
                            const topLeft = tpl.bounds || {
                                x: this.vToDcoordinate.x( x ) - bounds.width / 2,
                                y: this.vToDcoordinate.y( y ) - bounds.height / 2,
                            };
                            const clone = tpl.clone( topLeft );
                            // add the libs
                            if ( tpl && tpl.libraries ) {
                                this.addLibraries ( tpl.libraries );
                            }
                            return this.commandService.dispatch( DiagramCommandEvent.addDiagramShape, {
                                cloned: true,
                                shapes: clone.shapes,
                                groups: clone.groups,
                                connections: clone.connections,
                                dataDefs: clone.dataDefs,
                                templateData: {
                                    templateId,
                                    context: 'FAB-Panel',
                                },
                            });
                        }),
                    ),
                ),
            )),
        ).subscribe();
    }

    /**
     * This function  add URL data (here image) into the canvas using file receiver.
     * @param data
     * @param x
     * @param y
     */
    public addURLData( data: { url: string, name: string, upload: boolean}, x: number, y: number ) {
        const position = {
            x: this.vToDcoordinate.x( x ),
            y: this.vToDcoordinate.y( y ),
        };
        return this.fetchURL( data.url ).pipe(
            switchMap( blob => {
                const file: any = new Blob([ blob ], { type: blob.type });
                file.name = data.name;
                file.lastModifiedDate = new Date();
                const d = {
                    position,
                    upload: data.upload,
                };
                const importedFile = new ImportedFile( file );
                importedFile.importType = FileImportTypes.DragAndDrop;
                return this.fileReceiver.receiveFiles([ importedFile ], d );
            }),
        );
    }

    /**
     * Adds libraries that are passed in by the template
     * @param newLibs
     */
     public addLibraries( newLibs: [ string, LibraryState ][]) {
        if ( !newLibs || !newLibs.length ) {
            return;
        }
        const currLibs: ILoadedLibrary[] = _cloneDeep( this.state.get( 'CurrentLibraries' ));
        const orderedLibs = [];
        newLibs.forEach( item => {
            const index = currLibs.findIndex( l => l.id === item[0]);
            if ( index > -1 ) {
                orderedLibs.push( currLibs[index]);
                currLibs.splice( index, 1 );
            } else {
                orderedLibs.push({
                    id: item[0],
                    type: LibraryType.Static,
                    status: 'loading',
                    libGroup: this.libraryList.getMainGroupForLibrary( item[0]),
                    category: this.libraryList.getCategoryForLibrary( item[0]),
                } as ILoadedLibrary );
            }
        });
        currLibs.forEach( item => orderedLibs.push( item ));
        orderedLibs.forEach(( item, i ) => {
            item.order = i;
        });
        this.state.set( 'CurrentLibraries', orderedLibs );
        this.state.set( 'CurrentLibraryGroup', orderedLibs[0].libGroup );
    }

    /**
     * This function will fetch the data from the given URL
     * and returns the blob.
     * If the image cannot be load using the resource loader
     * because of a CORS issue, fall back to the fetch API
     * of creately.
     * @param url - URL to fetch
     * @returns - Blob
     */
    private fetchURL( url: string ): Observable<any> {
        return this.resourceLoader.load( url ).pipe(
            catchError(() => {
                if ( !this.isOffline ) {
                    return this.commandService.dispatch( DiagramCommandEvent.fetchUrl, { url: url }).pipe(
                        last(),
                        map( response => response.resultData[0].data ),
                    );
                } else {
                    this.showOfflineNotification();
                    return EMPTY;
                }
            }),
            tap( response => this.cacheData( url, response )),
            switchMap( response => {
                if ( response ) {
                    // NOTE: Here the response is a base64. To convert the base64
                    // to blob, we need to fetch it.
                    return from( fetch( response )).pipe(
                        switchMap( res => from( res.blob())),
                    );
                } else {
                    Logger.error( 'Failed to fetch' + url );
                    return of( null );
                }
            }),
            filter( value => !!value ),
        );
    }

    /**
     * Caches the droped resource on the canvas into index db.
     * @param url - dropped URL
     * @param response - Base64 of image data
     */
     private cacheData( url: string, response: string ) {
        const hash = md5( response );
        // If the key url and hashed url does not match only,
        // cache the hashed url. This is to eleminate duplicate entries.
        if ( url !== this.getImageUrl( hash )) {
            this.resourceLoader.store( url, this.getImageUrl( hash ), ResourceStatus.Uploaded );
        }
    }


    /**
     * Returns the custom image base url with hash value at the end
     * @param hash - hash value
     */
    private getImageUrl( hash: string ) {
        return AppConfig.get( 'CUSTOM_IMAGE_BASE_URL' )  + hash;
    }

    /**
     * This shows the offline notification.
     */
    private showOfflineNotification() {
        const notificationData = this.notificationMessages.getNotificationMessage( Notifications.OFFLINE_URL_IMPORT );
        this.notifierController.show( Notifications.OFFLINE_URL_IMPORT,
            notificationData.component, notificationData.type, notificationData.options, notificationData.collapsed );
    }
}

