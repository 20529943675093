import { AppConfig, PostMessageAPI } from 'flux-core';
import { Injectable } from '@angular/core';
import { NucleusAuthentication } from './../../../system/nucleus-authentication';
import { DataSourceDomain } from './model/data-source.model';
import { fromPromise } from 'rxjs/internal-compatibility';
import { filter, switchMapTo, take, tap, timeout } from 'rxjs/operators';

@Injectable()
export class CyclrService {
    constructor( protected auth: NucleusAuthentication, protected postMessage: PostMessageAPI ) {
    }

    public get httpUrl(): string {
        return AppConfig.get ( 'CYCLR_API_COMMANDS_URL' );
    }
    public async fetchPost<T = any>( body: any = {}): Promise<T> {
        const res = await fetch( `${this.httpUrl}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'creately-gravity-token': this.auth.token,
            },
            body: JSON.stringify( body ),
        });
        if ( res.status >= 400 && res.status < 600 ) {
            throw new Error( 'Internal server error' );
        }
        return res.json();
    }

    public async isAuthenticated( domain: DataSourceDomain ) {
        return this.fetchPost<boolean>({
            dataSource: domain,
            method: 'isAuthenticated',
        });
    }

    /**
     * Authenticates the cyclr connector for a specific domain.
     * @param domain authentication domain
     * @param authWaitTime number of seconds to wait before operation fails
     * @returns Observable
     */
    public authenticate( domain: DataSourceDomain, authWaitTime: number = 300 ) {
        const cyclrOriginDomain = AppConfig.get ( 'CYCLR_AUTH_DOMAIN' );
        const origin = [ cyclrOriginDomain ];
        let childWindow: Window;
        return fromPromise( this.fetchPost({
            dataSource: domain,
            method: 'getOAuthUrl',
            params: {
                target: window.location.href,
            },
        })).pipe(
            tap( url => childWindow = window.open( url.toString())),
            switchMapTo( this.postMessage.recvCyclerEvents( origin )),
            filter( msg => msg === 'done' ),
            take( 1 ),
            tap(() => childWindow.close()),
            timeout( authWaitTime * 1000 ),
        );
    }
}
