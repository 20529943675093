<div class="edata-container" [style.display]="(showHomeFolder | async) ? 'none' : 'block'" tooltip-tour-step="data-3">
      <div class="edata-container-header">
            <h2 class="edata-header" translate>EDATA_PANEL.DATABASES</h2>
            <button [class.fx-hidden]="(isFreeOrDemoUser |async)" class="btn-medium btn-secondary create-database-button" tooltip-tour-step="data-1" (click)="newDatabase()">
                  <span translate>EDATA_PANEL.NEW</span>
            </button>
            <button [class.fx-hidden]="(isFreeOrDemoUser |async)" class="nu-btn-icon btn-medium btn-secondary database-intigration-button" (click)="showIntegrationsDialog()">
                  <svg class="nu-icon nu-ic-apps">
                        <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-apps"></use>
                  </svg>
            </button>
      </div>
      <div [class.fx-hidden]="(isFreeOrDemoUser |async)">
      <div class="edata-database-search">
            <database-search (onTextChange)=onDatabaseSearchTextChange($event) [value]="selectedDbName | async"
                  (onSearchInProgress)="onSearchInProgress($event)"></database-search>
            <div class="database-search-result" [hidden]="(filteredDbs | async).length === 0 && ( filteredDataSources | async ).length === 0" >
                  <abs-dropdown #searchResultCtlr [settings]="{closeOnBlur: false}"  direction="bottom" [autoPosition]="false">
                        <simple-dropdown-button ddbutton class="fx-hidden" [items]="filteredDbs | async">
                        </simple-dropdown-button>
                        <simple-dropdown-item dditem class="database-search-result-item" *ngFor="let database of filteredDbs | async" [item]="database" (changed)="linkEDataToDiagram( database.id, database.type )">
                              <div class="database-item-icon">
                                    <svg class="nu-icon">
                                          <use *ngIf="database.type === 'folder'" xlink:href="./assets/icons/symbol-defs.svg#nu-ic-data"></use>
                                          <use *ngIf="database.type === 'team'" xlink:href="./assets/icons/symbol-defs.svg#nu-ic-organination"></use>
                                    </svg>
                              </div>
                              <div class="database-item-label">{{ database.name }}</div>
                        </simple-dropdown-item>
                        <simple-dropdown-item dditem *ngIf="(filteredDbs | async).length > 0 && (filteredDataSources | async).length > 0">
                              <div class="separator-line"></div>
                        </simple-dropdown-item>
                        <simple-dropdown-item dditem class="database-search-result-item" *ngFor="let database of filteredDataSources | async" [item]="database" (changed)="searchIntegration( database.id )">
                              <div class="database-item-icon">
                                    <svg class="nu-icon">
                                          <use *ngIf="database.id === 'git'" xlink:href="./assets/icons/symbol-defs.svg#nu-ic-github"></use>
                                    </svg>
                              </div>
                              <div class="database-item-label">{{ database.name }}</div>
                        </simple-dropdown-item>
                  </abs-dropdown>
            </div>
      </div>
      <div *ngIf="(setupDatabaseState | async) && (setupDatabaseState | async) !== 'hidden'" class="setup-database">
            <div (click)="toggleSetDatabase()" class="head">
                  <h4 translate>EDATA_PANEL.SETUP_DATABASE.HEADING</h4>
                  <svg class="nu-icon nu-ic-small">
                        <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-info"></use>
                  </svg>
            </div>
            <div *ngIf="(setupDatabaseState | async) == 'expanded'">
                  <span translate>EDATA_PANEL.SETUP_DATABASE.DESC</span>
                  <div class="button-container">
                        <button (click)="setupDatabase()" class="btn-small btn-primary">
                              <span translate>EDATA_PANEL.SETUP_DATABASE.SETUP_NOW</span>
                        </button>
                        <button (click)="toggleSetDatabase()" class="btn-small btn-secondary">
                              <span translate>EDATA_PANEL.SETUP_DATABASE.SETUP_LATER</span>
                        </button>
                  </div>
            </div>
      </div>
      <div class="edata-items-container">
            <perfect-scrollbar class="col-menu-scrollbar" >
                  <div #dataSourcePanelContainer></div>
                  <collapsible-menu #folderEData class="edata-collapsible-menu"></collapsible-menu>
            </perfect-scrollbar>
            <!-- <database-collapsible-menu-item *ngFor="let item of menuItems" [item]="item"></database-collapsible-menu-item> -->
      </div>
      </div>
      <!-- <div *ngIf="hasCustomDbPermission && (hasFolderEditorAccess | async)" class="edata-create-option">
            <div class="edata-options" >
                  <abs-dropdown #dropdown [settings]="{ closeOnItemClicked: true, openOnHover: false,
                      closeOnBlur: true, multiselectable: false }" direction="top" alignment="right">
                      <simple-dropdown-button ddbutton>
                          <button class="btn-icon">
                              <svg class="nu-icon">
                                  <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-more"></use>
                              </svg>
                          </button>
                      </simple-dropdown-button>
                      <simple-dropdown-item [style.display]="(showDisconnectedDbs | async) ? 'none' : 'block'" (click)="showDisconnectedDbs.next(true)" dditem >
                        <span class="body" translate>EDATA_PANEL.SHOW_MORE</span>
                      </simple-dropdown-item>
                      <simple-dropdown-item [style.display]="!(showDisconnectedDbs | async) ? 'none' : 'block'" (click)="showDisconnectedDbs.next(false)" dditem>
                        <span class="body" translate>EDATA_PANEL.SHOW_LESS</span>
                      </simple-dropdown-item>
                  </abs-dropdown>
            </div>
      </div>
      <div [ngClass]="(hasCustomDbPermission && (hasFolderEditorAccess | async)) ? 'edata-items' : 'edata-items-without-btn'" >
            <perfect-scrollbar class="col-menu-scrollbar" >
                  <div *ngIf="(showNoModel | async) || (isEdataEmpty | async)" class="empty-state-section">
                        <message-card [title]="emptyStateTitle" [body]="emptyStateBody"
                        [titleIcon]="emptyStateIcon"></message-card>      
                  </div>
            </div>
            <div [ngClass]="(hasCustomDbPermission && (hasFolderEditorAccess | async)) ? 'edata-items' : 'edata-items-without-btn'" >
                  <perfect-scrollbar class="col-menu-scrollbar" >
                        <div *ngIf="(showNoModel | async) || (isEdataEmpty | async)" class="empty-state-section">
                              <div class="empty-title">
                                    <span translate>EDATA_PANEL.EMPTY_SCREEN.EMPTY_TITLE</span>
                              </div>
                              <message-card [title]="emptyStateTitle" [body]="emptyStateBody"
                              [titleIcon]="emptyStateIcon"></message-card> 
                              <div class="fx-margin-bottom-30 fx-margin-right-10">
                                    <button class="btn-link btn-medium nu-ic-help fx-center" >
                                          <svg class="nu-icon nu-icon-xsmall fx-margin-right-5 help-btn">
                                                <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-help"></use>
                                          </svg>
                                          <a translate href="https://support.creately.com/hc/en-us/articles/5118701120143-How-to-assign-tasks-in-a-workspace-" target="_blank">ALERTS.EMPTY_SCREEN.LEARN_MORE</a>
                                    </button>
                              </div>    
                        </div>
                        <div [class.fx-hidden]="!( isTeamUser | async )" class="global-db-section">
                              <collapsible-menu #teamSharedEData class="edata-collapsible-menu">
                              </collapsible-menu>
                              <div class="public-db-place-holder-container" *ngIf="!( hasLinkedGlobalDB | async ) && ( teamSharedDBs | async )?.length > 0">
                                    <div class="public-db-place-holder public-db-place-holder-header body body--bold" translate>
                                          EDATA_PANEL.PLACEHOLDER.ORGANIZATION.HEADING
                                          <label class="edata-link-db-icon-left" tooltip placement="right" >
                                                <svg class="nu-icon nu-icon-small icon-color-grey edata-link-db-icon-left">
                                                      <use [attr.xlink:href]="'./assets/icons/symbol-defs.svg#nu-ic-info'"></use>
                                                </svg>
                                                <span class="tooltip-content">
                                                      <p class="tooltip-header" translate>EDATA_PANEL.TOOLTIP.PUBLIC_DATABASES.HEADING</p>
                                                      <p class="tooltip-body" translate>EDATA_PANEL.TOOLTIP.PUBLIC_DATABASES.DESCRIPTION</p>
                                                </span>
                                          </label>
                                    </div>
                                    <div class="fx-padding-left-8 show-edata-more-lesss" *ngIf="!( showAllDatabases | async )" (click)="setShowAllDatabases( true )">
                                          <div class="edata-link-db-icon-left" >
                                                <svg class="nu-icon nu-icon-small icon-color-grey">
                                                      <use [attr.xlink:href]="'./assets/icons/symbol-defs.svg#nu-ic-link'"></use>
                                                </svg>
                                          </div>
                                          <div class="font-blue-color caption" translate>EDATA_PANEL.SHOW_ALL_DBS</div>
                                    </div>
                              </div>
                              <div class="fx-padding-left-8 show-edata-more-lesss" *ngIf="!( showAllDatabases | async ) && ( hasLinkedGlobalDB | async )" (click)="setShowAllDatabases( true )">
                                    <div>
                                          <svg class="nu-icon nu-icon-small icon-color-grey">
                                                <use [attr.xlink:href]="'./assets/icons/symbol-defs.svg#nu-ic-link'"></use>
                                          </svg>
                                    </div>
                                    <div class="font-blue-color caption" translate>EDATA_PANEL.SHOW_ALL_DBS</div>
                                    <div class="edata-link-db-icon-left" tooltip placement="right" >
                                          <svg class="nu-icon nu-icon-small icon-color-grey">
                                                <use [attr.xlink:href]="'./assets/icons/symbol-defs.svg#nu-ic-info'"></use>
                                          </svg>
                                          <span class="tooltip-content">
                                                <p class="tooltip-header" translate>EDATA_PANEL.TOOLTIP.PUBLIC_DATABASES.HEADING</p>
                                                <p class="tooltip-body" translate>EDATA_PANEL.TOOLTIP.PUBLIC_DATABASES.DESCRIPTION</p>
                                          </span>
                                    </div>
                              </div>
                              <div class="fx-padding-left-8 fx-margin-top-10" *ngIf="showAllDatabases | async">
                                    <div class="body edata-disconnected-db-item" *ngFor="let database of teamSharedDBs | async">
                                          <div class="edata-disconnected-db-item-label fx-ellipsis">
                                                {{database.name}}
                                          </div>
                                          <div (click)="linkEDataToDiagram( database.id, 'global' )" tooltip placement="right">
                                                <svg class="nu-icon nu-icon-small icon-color-grey">
                                                      <use [attr.xlink:href]="'./assets/icons/symbol-defs.svg#nu-ic-link'"></use>
                                                </svg>
                                                <span class="tooltip-content" translate>EDATA_PANEL.TOOLTIP.CONNECT_TO_DATABASE</span>
                                          </div>
                                    </div>
                                    <div class="show-edata-more-lesss" *ngIf="( teamSharedDBs | async )?.length > 0">
                                          <div class="font-blue-color caption" (click)="setShowAllDatabases( false )" translate >EDATA_PANEL.SHOW_LESS</div>
                                    </div>
                              </div>
                        </div>
                        <div class="folder-db-section">
                              <div class="fx-padding-left-8 edata-section-title overline overline--lg" translate
                                    *ngIf="( disconnectedDBs | async )?.length > 0">EDATA_PANEL.FOLDER</div>    
                              <collapsible-menu #folderEData class="edata-collapsible-menu">
                              </collapsible-menu>
                              <div *ngIf="hasCustomDbPermission && ( disconnectedDBs | async )?.length > 0">
                                    <div class="public-db-place-holder-container" *ngIf="!( showDisconnectedDbs | async )">
                                          <div class="public-db-place-holder public-db-place-holder-header body body--bold" translate>
                                                EDATA_PANEL.PLACEHOLDER.FOLDER.HEADING
                                          </div>

                                          <div class="public-db-place-holder public-db-place-holder-no-db caption" translate
                                                *ngIf="( showAllDatabases | async ) && ( teamSharedDBs | async )?.length < 1"></div>
                                          <div class="show-edata-more-lesss" (click)="setDisconnectedDBs( true )">
                                          
                                                <div class="edata-link-db-icon-left" >
                                                      <svg class="nu-icon nu-icon-small icon-color-grey">
                                                            <use [attr.xlink:href]="'./assets/icons/symbol-defs.svg#nu-ic-link'"></use>
                                                      </svg>
                                                </div>
                                                <div class="font-blue-color caption" translate>EDATA_PANEL.SHOW_MORE</div>
                                                <div class="edata-link-db-icon-left" tooltip placement="right" >
                                                      <svg class="nu-icon nu-icon-small icon-color-grey">
                                                            <use [attr.xlink:href]="'./assets/icons/symbol-defs.svg#nu-ic-info'"></use>
                                                      </svg>
                                                      <span class="tooltip-content">
                                                            <p class="tooltip-header" translate>EDATA_PANEL.TOOLTIP.FOLDER_DATABASES.HEADING</p>
                                                            <p class="tooltip-body" translate>EDATA_PANEL.TOOLTIP.FOLDER_DATABASES.DESCRIPTION</p>
                                                      </span>
                                                </div>
                                          </div>
                                    </div>
                                    <div class="fx-padding-left-8" *ngIf="showDisconnectedDbs | async">
                                          <div class="body edata-disconnected-db-item" *ngFor="let database of disconnectedDBs | async">
                                                <div class="edata-disconnected-db-item-label fx-ellipsis">
                                                      {{database.name}}
                                                </div>
                                                <div (click)="linkEDataToDiagram( database.id, 'folder' )" tooltip placement="right">
                                                      <svg class="nu-icon nu-icon-small icon-color-grey">
                                                            <use [attr.xlink:href]="'./assets/icons/symbol-defs.svg#nu-ic-link'"></use>
                                                      </svg>
                                                      <span class="tooltip-content" translate>EDATA_PANEL.TOOLTIP.CONNECT_TO_DATABASE</span>
                                                </div>
                                          </div>
                                          <div class="show-edata-more-lesss" *ngIf="( disconnectedDBs | async )?.length > 0" >
                                                <div class="font-blue-color caption" (click)="showDisconnectedDbs.next( false )" translate >EDATA_PANEL.SHOW_LESS</div>
                                          </div>
                                          <div class="public-db-place-holder-container" *ngIf="( showDisconnectedDbs | async ) && ( disconnectedDBs | async )?.length < 1">
                                                <div class="public-db-place-holder public-db-place-holder-header body body--bold" translate>
                                                      EDATA_PANEL.PLACEHOLDER.FOLDER.HEADING
                                                </div>
                                                <div class="public-db-place-holder caption" translate>EDATA_PANEL.PLACEHOLDER.FOLDER.EMPTY</div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <div>
                              <div class="fx-padding-left-8 edata-section-title overline overline--lg" translate>EDATA_PANEL.INTEGRATIONS</div>
                              <collapsible-menu #integratedEData class="edata-collapsible-menu">
                              </collapsible-menu>
                        </div>
                  </perfect-scrollbar>
            </div>
      </div>
      <div *ngIf="(isFreeOrDemoUser |async)">
            <message-card [title]="emptyStateTitle" [body]="emptyStateBody"
                              [titleIcon]="emptyStateIcon"></message-card>
            <div class="edata-empty-container fx-margin-bottom-30">
                  <div class="btn-style fx-center">
                        <button class="btn-primary btn-medium" 
                        (click)="showUpgradeDialog()">
                              <label translate>LABELS.UPGRADE</label>
                        </button>
                        <button class="btn-link btn-medium fx-margin-left-5 nu-ic-help fx-margin-top-5" >
                              <svg class="nu-icon nu-icon-xsmall help-btn fx-margin-right-5">
                                    <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-help"></use>
                              </svg>
                              <a translate href="https://support.creately.com/hc/en-us/articles/5118701120143-How-to-assign-tasks-in-a-workspace-" target="_blank">ALERTS.EMPTY_SCREEN.LEARN_MORE</a>
                        </button>
                  </div>
                  <div>
                        <div class="fx-padding-left-8 edata-section-title overline overline--lg" translate>EDATA_PANEL.INTEGRATIONS</div>
                        <collapsible-menu #integratedEData class="edata-collapsible-menu">
                        </collapsible-menu>
                  </div>
            </perfect-scrollbar>
      </div> -->
      <!-- <p class="edata-message grey" [style.display]="(showNoModel | async) ? 'flex' : 'none'" [innerHTML]="'LABELS.NO_MODEL_LOADED' | translate"></p> -->

      <div *ngIf="(isFreeOrDemoUser |async)">
            <message-card [title]="emptyStateTitle" [body]="emptyStateBody"
                            [titleIcon]="emptyStateIcon"></message-card>
            <div class="empty-slides-container fx-margin-bottom-30">
                <div class="btn-style fx-center">
                    <button class="btn-primary btn-medium" 
                    (click)="showUpgradeDialog()">
                        <label translate>LABELS.UPGRADE</label>
                    </button>
                    <button class="btn-link btn-medium fx-margin-left-5 nu-ic-help fx-margin-top-5" >
                        <svg class="nu-icon nu-icon-xsmall help-btn fx-margin-right-5">
                            <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-help"></use>
                        </svg>
                        <a translate href="https://support.creately.com/hc/en-us/articles/5118701120143-How-to-assign-tasks-in-a-workspace-" target="_blank">ALERTS.EMPTY_SCREEN.LEARN_MORE</a>
                    </button>
                </div>
            </div>
      </div>
</div>

<p class="edata-message grey" [style.display]="(showHomeFolder | async) ? 'flex' : 'none'" [innerHTML]="'LABELS.MODEL_HOME_FOLDER' | translate"></p>
