import { Injectable } from '@angular/core';
import { CyclrService } from '../cyclr-service';
import { DataSourceDomain } from '../model/data-source.model';
import { AppConfig } from '../../../../../../../libs/flux-core/src';

/**
 * Cached github service.
 */
@Injectable()
export class GithubService extends CyclrService {
    private authenticated: Promise<boolean>;
    private ownersAndRepos: Promise<any>;
    private userPromise: Promise<any>;

    public isAuthenticated(): Promise<boolean> {
        if ( !this.authenticated ) {
            this.authenticated = this.fetch( 'isAuthenticated' );
        }
        return this.authenticated;
    }

    public async authorize( authWaitTime: number ): Promise<boolean> {
        const url = await this.fetch( 'getOAuthUrl', {
            target: window.location.href,
        });
        const childWindow = window.open( url.toString());
        return new Promise(( resolve, reject ) => {
            const timeoutId = setTimeout(() => {
                reject( 'authentication timed out' );
            }, authWaitTime );
            const cyclrOriginDomain = AppConfig.get ( 'CYCLR_AUTH_DOMAIN' );
            const origin = [ cyclrOriginDomain ];
            this.postMessage.recvCyclerEvents( origin )
                .subscribe( message => {
                    if ( message === 'done' ) {
                        clearTimeout( timeoutId );
                        childWindow.close();
                        resolve( true );
                        this.authenticated = Promise.resolve( true );
                    }
                });
        });
    }

    public getUser( forceReload: boolean = false ) {
        if ( !this.userPromise || forceReload ) {
            this.userPromise = this.fetch( 'getUser' );
        }
        return this.userPromise;
    }

    public fetchOwnersAndRepos( forceReload: boolean = false ) {
        if ( !this.ownersAndRepos || forceReload ) {
            this.ownersAndRepos = this.fetch( 'getAllRepos' );
        }
        return this.ownersAndRepos;
    }

    public initCycle() {
        return this.fetch( 'initCycle' );
    }

    public getIssuesOrRequests( params: any ) {
        return this.fetch( 'getIssuesOrRequests', params );
    }

    public fetch( method: string, params?: any ) {
        const payload: any = {
            dataSource: DataSourceDomain.Github,
            method,
        };
        if ( params ) {
            payload.params = params;
        }
        return this.fetchPost( payload );
    }
}
