import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { DiagramLocatorLocator } from '../../../base/diagram/locator/diagram-locator-locator';
import { EDataLocatorLocator } from '../../../base/edata/locator/edata-locator-locator';
import { DefinitionLocator } from '../../../base/shape/definition/definition-locator.svc';
import { of, merge, combineLatest, Subscription } from 'rxjs';
import { take, switchMap, tap } from 'rxjs/operators';
import { IDataSource, IDataSourceModel } from './model/data-source.model';
import { cloneDeep, isEqual } from 'lodash';
import { ShapeModel } from 'apps/nucleus/src/base/shape/model/shape.mdl';


@Component({
    template: `
        <div class="data-source-card" draggable="true" (dragstart)="handleDragStart( $event )">
            Fetched results ( {{data.length }} )
        </div>
    `,
    selector: 'data-source-card-item',
    styleUrls: [ './data-source-card-item.cmp.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataSourceCardListItem implements OnDestroy {
   /**
    * data source model data getting from the source.
    */
   @Input()
   public data: IDataSourceModel[];

   /**
    * The definition id of the datasource EDATA model.
    */
   @Input()
   protected eDefId: string;

   @Input()
   protected dataSource: IDataSource;

   @Input()
   protected definedSearchQuery: string;
   /**
    * The definition id of the datasource SHAPE.
    */
   @Input()
   protected defId: string;

   /**
    * version of the SHAPE definition.
    */
   @Input()
   protected version: number;

   /**
    * Holds the currentLibraries state subscription.
    */
   protected subs: Subscription[];

   constructor(
       protected defLocator: DefinitionLocator,
       private el: EDataLocatorLocator,
       protected ll: DiagramLocatorLocator,
   ) {
       this.subs = [];
   }

   /*
   * Handle the drag start event on the library tile
   * Hide popover preview if it is open
   * @param event
   * @param data
   */
   public handleDragStart( event: any ) {
       const sub1 = this.ll.forCurrentObserver( false ).pipe(
           take( 1 ),
           switchMap( locator => locator.getDiagramEData().pipe(
               switchMap( eDataList => {
                   if ( !eDataList ) {
                       return of([]);
                   }
                   const eSubs = [];
                   eDataList.forEach( eId => {
                       const eSub = this.el.getEData( eId ).pipe(
                           switchMap( eLocator => eLocator.getEDataModelOnce()));
                       eSubs.push( eSub );
                   });
                   return merge( ...eSubs );
               }),
           )),
       ).subscribe();

       const sub2 = combineLatest(
           this.el.currentEDataModelsOnce(),
           this.defLocator.getDefinition( this.defId, this.version ),
           ).pipe(
           take( 1 ),
           tap(([ eDataModels, shapeDef ]) => {

               const gitEdata = ( eDataModels || []).find( ed => ed.defId === this.eDefId );
               const mappedEntities = gitEdata && gitEdata.entities ? Object.keys( gitEdata.entities )
                .map( key => gitEdata.entities[ key ]) : null;

               const shapes = {};

               let entityListModel;
               if ( gitEdata && gitEdata.entityLists ) {
                   entityListModel = Object.values( gitEdata.entityLists )
                   .find( el => el.search === this.definedSearchQuery );
               }
               this.data.forEach( item => {
                    const dataSource = this.getDataSource( item.dataSourceId );
                    let entity;
                    if ( gitEdata && gitEdata.entities && mappedEntities ) {
                        entity = mappedEntities.find( e => isEqual( e.dataSource, dataSource ));
                    }
                    const shapeData: ShapeModel =  cloneDeep( shapeDef );
                    shapeData.name = item.title;
                    shapeData.triggerNewEData = true;
                    shapeData.data = {};
                    Object.assign( shapeData.data, shapeData.dataDef );
                    shapeData.data.number.value = item.dataSourceId.toString();
                    shapeData.data.title.value = item.title.toString();
                    shapeData.data.description.value = item.description;
                    shapeData.data.tags.value = item.tags.filter( tag => tag.name !== null );
                    shapeData.data.people.value = {
                        source: item.people.source,
                        people: item.people.people.filter ( p => p.fullName !== null && p.id !== null )},
                    shapeData.data.commentCount.value = 0;
                    ( shapeData as any ).dataSource = dataSource;
                    shapeData.entityListId = entityListModel?.id;
                    if ( gitEdata ) {
                        shapeData.eData = {
                            [gitEdata.id]: entity?.id || null,
                        };
                    }
                    shapes[ item.dataSourceId ] = shapeData;
               });
               event.dataTransfer.effectAllowed = 'move';
               event.dataTransfer.setData( 'PreDifQueries',
                JSON.stringify({
                    eDefId: this.eDefId,
                    definedSearchQuery: this.definedSearchQuery,
                    shapes: shapes,
                }));

           }),
       ).subscribe();
       this.subs.push( sub1 );
       this.subs.push( sub2 );
   }

   /**
    * Unsubscribes from all subscriptions.
    */
   public ngOnDestroy(): void {
       this.subs.forEach( sub => {
           sub.unsubscribe();
       });
       this.subs = [];
   }


   protected getDataSource( dataSourceId ): IDataSource {
       const dataSource = cloneDeep( this.dataSource );
       dataSource.data.dataSourceId = dataSourceId ;
       return dataSource;
   }

}
