import { AfterViewInit, ChangeDetectorRef, Component,
     ComponentFactoryResolver,
      ComponentRef, Injector, Input, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EDataModel } from 'apps/nucleus/src/base/edata/model/edata.mdl';
import { DefinitionLocator } from 'apps/nucleus/src/base/shape/definition/definition-locator.svc';
import { DynamicComponent } from 'flux-core/src/ui';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { EdataSearchResultItem } from './../../search-results/edata-search-result-item/edata-search-result-item.cmp';
import { LogicLabelComponent } from './logic-label.cmp';
import { SearchFormItem } from './../search-form-item/search-form-item.cmp';
import { MapOf } from 'flux-core';
import { EntitySearchService } from '../entity-search-service';
import { EDataRegistry } from '../../../../../../base/edata/edata-registry.svc';
import { DataType } from 'flux-definition';
import { CsvUtil } from '../../../../../../base/diagram/export/csv-util';

@Component({
    selector: 'custom-entity-search',
    templateUrl: 'custom-entity-search.cmp.html',
    styleUrls: [ 'custom-entity-search.cmp.scss' ],
})

export class CustomEntitySearchComponent extends DynamicComponent implements AfterViewInit {

    public static valueTypes = {
        [DataType.BINARY]: 'boolean',
        // [DataType.EMOJI]: 'string',
        [DataType.NUMBER]: 'number',
        [DataType.NUMBER_SLIDER]: 'number',
        [DataType.NUMBER_COUNTER]: 'number',
        // [DataType.NUMBER_OPTIONS]: 'number[]',
        [DataType.STRING]: 'string',
        [DataType.DATE]: 'date',
        [DataType.STRING_LONG]: 'string',
        [DataType.STRING_HTML]: 'string',
        [DataType.COLOR]: 'identifier',
        // [DataType.IMAGE]: 'string',
        // [DataType.OPTION]: 'string',
        [DataType.OPTION_CHOICE]: 'identifier',
        [DataType.OPTION_LIST]: 'string',
        // [DataType.OPTION_LIST_COMBO]: 'string',
        // [DataType.OPTION_IMAGES]: 'string',
        // [DataType.GRID_IMAGES]: 'string',
        // [DataType.CUSTOM_SHAPE_STYLE]: 'any',
        // [DataType.CUSTOM_STYLE_PALETTES]: 'string',
        // [DataType.TEXT_POSITION]: 'any',
        // [DataType.CHILD_SHAPE]: 'any',
        // [DataType.NESTED_DATAITEM_LIST]: 'any',
        // [DataType.NESTED_DATAITEM_OBJECT]: 'any',
        // [DataType.VIEW]: 'any',
        // [DataType.PEOPLE]: 'any',
        // [DataType.USERS]: 'any',
        [DataType.TAGS]: 'tags',
        [DataType.IDENTIFIER]: 'string',
        // [DataType.LOOKUP]: 'string[]',
        [DataType.FORMULA]: 'default',
    };

    @Input() public searchResult: BehaviorSubject<ComponentRef<EdataSearchResultItem>[]> = new BehaviorSubject([]);
    @Input() public eDataModel: EDataModel;
    public expandToggle = true;

    @Output() public closed: Subject<boolean> = new Subject();
    public dataLabelListObservable: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public dataLabelList = [];

    public rulesList = {
        default: [
            'equal',
            'contain',
            'notEqual',
            'lessThan',
            'lessThanInclusive',
            'greaterThan',
            'greaterThanInclusive',
        ],
        string: [
            'equal',
            'contain',
            'notEqual',
        ],
        identifier: [
            'equal',
            'notEqual',
        ],
        number: [
            'equal',
            'notEqual',
            'lessThan',
            'lessThanInclusive',
            'greaterThan',
            'greaterThanInclusive',
        ],
        date: [
            'equal',
            'notEqual',
            'lessThan',
            'lessThanInclusive',
            'greaterThan',
            'greaterThanInclusive',
        ],
        tags: [
            'contains',
        ],
        boolean: [
            'equal',
        ],
    };

    public sortByList = [
        {
            viewValue: 'Ascending' ,
            value: 1,
            default: true,
        },
        {
            viewValue: 'Descending' ,
            value: -1,
        },
    ];

    @ViewChild( 'searchForms', { read: ViewContainerRef, static: false })
    public searchForms: ViewContainerRef;

    public change: Subject <string> = new Subject();

    public entityTypeSelectController: FormControl = new FormControl( '' );
    public sortDataItemController: FormControl = new FormControl( '' );
    public sortByController: FormControl = new FormControl( this.defaultSortValue );
    public dataTypesListObservable: Observable<string[]>;
    public sortDataItemListObservable: Observable<any[]>;
    public querySubject: BehaviorSubject<string> = new BehaviorSubject( '' );
    public searchQuery: string;

    public instanceList: ComponentRef<any>[] = [];
    public top: string = '75px';

    constructor(
        protected componentFactoryResolver: ComponentFactoryResolver,
        protected injector: Injector,
        protected defLocator: DefinitionLocator,
        private changeDetector: ChangeDetectorRef,
        private entitySearchService: EntitySearchService,
        ) {
        super( componentFactoryResolver, injector );

    }

    private get defaultSortValue(): number {
        return this.sortByList.find( item => item.default ).value;
    }

    ngAfterViewInit() {
        this.dataTypesListObservable = this.entityTypeSelectController.valueChanges.pipe(
            startWith( '' ),
            map( value => this._filter( value, this.getEntityTypes())),
          );
        this.sortDataItemListObservable = combineLatest(
            this.sortDataItemController.valueChanges.pipe( startWith( '' )),
            this.dataLabelListObservable ).pipe(
            map(([ value, dataList ]) => typeof value === 'string' ? this._filter( value, dataList ) :
            this._filter( value.name, this.dataLabelListObservable.value )),
        );
        const logic: BehaviorSubject<string> = new BehaviorSubject<string>( '' );
        this.insertForm( logic );
        this.calculatePosition();
    }

    public selectDataTypeSelector( event: any ) {
        const entityTypeName = this.entityTypeSelectController.value;
        const entityType = this.getEntityTypes().find( type => type.name === entityTypeName );
        const dataItems: any = this.eDataModel.getEntityDef( entityType.defId ).dataItems;

        if ( this.eDataModel.isCustom ) {
            this.dataLabelList = Object.keys( dataItems )
            .filter( key => CustomEntitySearchComponent.valueTypes[dataItems[key].type])
            .filter( key => dataItems[key]?.visibility
                && dataItems[key].visibility.find(( value: any ) => value.type === 'editor' ))
                .map( key =>
                    ({
                        name: dataItems[key].label,
                        id: dataItems[key].id,
                        type: dataItems[key].type,
                        searchType: CustomEntitySearchComponent.valueTypes[dataItems[key].type],
                    }));
        } else {
            this.dataLabelList = Object.keys( dataItems )
                .filter( key => CustomEntitySearchComponent.valueTypes[dataItems[key].type])
                .map( key =>
                    ({
                        name: dataItems[key].label || key[0].toUpperCase() + key.slice( 1 ),
                        id: dataItems[key].id || key,
                        type: dataItems[key].type,
                        searchType: CustomEntitySearchComponent.valueTypes[dataItems[key].type],
                    }));
        }

        this.dataLabelListObservable.next( this.dataLabelList );

    }

    public dataItemListDisplayHelper( selectionObj ) {
        return selectionObj ? selectionObj.name : '';
    }

    public cancel() {
        this.searchResult.next([]);
        this.closed.next( true );
    }
    public emptyResult( resetSearch = true ) {
        this.searchResult.next([]);
        if ( resetSearch ) {
            this.resetSearchForm();
        }
    }
    public async onSubmit() {
        const dateParser = CsvUtil.getParser( DataType.DATE );
        const numberParser = CsvUtil.getParser( DataType.NUMBER );
        const entityType = this.entityTypeSelectController.value;
        const sortByDataItemId =  this.sortDataItemController.value;
        const sortByType = this.sortByController.value;
        const logic = this.instanceList[0].instance.logic.value;
        const conditions = this.instanceList.map(( form: ComponentRef<SearchFormItem> ) => {
            const { dataItemLabel, rules } = form.instance.searchForm.value ;
            let value = form.instance.searchForm.value.value;
            if ( dataItemLabel.type === DataType.DATE ) {
                const timestamp = dateParser( value ) as number;
                value = ( new Date( timestamp )).toISOString();
            } else if ( dataItemLabel.type === DataType.BINARY ) {
                // convert string to boolean
                value = ( value.toLowerCase() === 'true' || value === '1' );
            }
            if ( dataItemLabel.type === DataType.NUMBER ) {
                value = numberParser( value ) as number;
            }
            if ( dataItemLabel.type === DataType.BINARY ) {
                value = value === 'true' || value === '1';
            }
            return {
                dataItemId: dataItemLabel.id,
                type: dataItemLabel.searchType,
                value: value,
                operator: rules,
            };
        });
        const $select: any = {
            [logic === 'OR' ? '$or' : '$and' ]: conditions,
        };

        const payload: MapOf<any> = {
            entityDefId: this.getEntityTypes().find( t => t.name === entityType ).defId,
            eDataId: this.eDataModel.id,
            $select,
        };

        if ( sortByType && sortByDataItemId ) {
            payload.$sort = {
                [sortByDataItemId.id] : sortByType,
            };
        }
        const results: any[] = await this.entitySearchService.fetchPost( payload );
        const result  = results.map( e => ({ id: e.id }));
        const componentsData =  await this.getResultComponentsDataList( result );
        conditions.filter( c => c.type === DataType.DATE ).forEach( c => {
            c.value = Date.parse( c.value );
        });
        this.searchQuery = JSON.stringify( payload );
        this.querySubject.next( JSON.stringify( payload ));
        this.searchResult.next( componentsData );
    }

    public addAnd() {
        this.addCondition( 'AND' );
        this.calculatePosition();
    }

    public addOr() {
        this.addCondition( 'OR' );
        this.calculatePosition();
    }

    private resetSearchForm() {
        this.searchForms.clear();
        this.instanceList = [];
        this.changeDetector.detectChanges();
        const logic: BehaviorSubject<string> = new BehaviorSubject<string>( '' );
        this.insertForm( logic );
        this.entityTypeSelectController.reset( '' );
        this.sortDataItemController.reset( '' );
        this.sortByController.reset( this.defaultSortValue );
    }

    private getEntityTypes() {
        let entityDefs = this.eDataModel.customEntityDefs;
        if ( !this.eDataModel.isCustom ) {
            entityDefs = EDataRegistry.instance.getEDataDef( this.eDataModel.defId ).entityDefs;
        }
        return Object.keys( entityDefs )
            .map( key => ({
                name: entityDefs[key].name,
                defId : entityDefs[key].id || key,
            }));
    }

    private addCondition( operator: 'AND' | 'OR' ) {
        const logic = this.instanceList[0].instance.logic;
        logic.next( operator );
        this.insertLogicLabel( logic );
        this.insertForm( logic );
    }

    private insertForm( logicObs:  BehaviorSubject<string> ) {
        const form: ComponentRef<SearchFormItem> = this.makeComponent( SearchFormItem );
        form.instance.id = this.searchForms.length;
        form.instance.dataLabelList = this.dataLabelListObservable;
        form.instance.rulesList = this.rulesList;
        form.instance.logic = logicObs;
        this.instanceList.push( form );
        this.insert( this.searchForms, form );
    }

    private insertLogicLabel( logic ) {
        const logicLabel = this.makeComponent( LogicLabelComponent );
        logicLabel.instance.logic = logic;
        this.insert( this.searchForms, logicLabel );
    }


    private async getResultComponentsDataList( results: any[]) {
        const resultComponentsList: any[] = [];
        await Promise.all( results.map( async result => {
            const entityModel = this.eDataModel.getEntity( result?.id );
            const shapeDefId = entityModel.getShapeDefIdForContext();
            const def = await this.defLocator.getDefinition( shapeDefId.id, shapeDefId.version ).toPromise();
            const data = {
                entity : entityModel,
                eDataModel : this.eDataModel,
                definition : def,
                eDefId : entityModel.eDefId,
                defId : shapeDefId.id,
                version : shapeDefId.version,
            };
            resultComponentsList.push( data );
        }));
        return resultComponentsList;

    }

    private _filter( name: string , dataSet: any[]): any[] {
        const filterValue = name.toLowerCase();
        return dataSet.filter( option => option.name.toLowerCase().includes( filterValue ));
    }

    /**
     * Calculate the position of the popup on the screen so it is always fully visible
     */
    private calculatePosition () {
        const edataItem = document.getElementById( this.eDataModel.id );
        const element = document.getElementById( 'custom-search-panel' );

        const rect = edataItem.getBoundingClientRect();
        const viewportHeight = window.innerHeight;

        let cutOff = 0;

        const popupTop = rect.top;
        const popupHeight = element.getBoundingClientRect().height;

        if ( popupHeight > viewportHeight - rect.top - 100 ) {
            cutOff = popupHeight - ( viewportHeight - rect.top - 100 );
        }
        this.top = `calc(${popupTop}px - ${cutOff}px)`;
    }

}
