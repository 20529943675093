import { flatten } from 'lodash';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

/**
 * SearchService
 * This search service registers the available search providers
 * and provides the search functionalities for the expected provider
 */
@Injectable()
export class SearchService {

    private searchProviders: { [ type in SearchResultItemType ]: ISearchProvider[] };

    /**
     * Initialize!
     */
    constructor() {
        this.searchProviders = {
            shape: [],
            image: [],
            libs: [],
            document: [],
            edata: [],
        };
    }

    /**
     * Registers a search provider
     * @param search The search instance.
     */
    public register( search: ISearchProvider ): void {
        this.searchProviders[ search.type ].push( search );
    }

    /**
     * Search and returns an array of ISearchResultItem
     * for the given providers
     * @param text - The query to test
     * @param providers - prodviders to be used in search based on what search will be used on
     * @param options - options for searh providers
     */
    public search( text: string | any, providers: SearchResultItemType[],
                   options: ISearchProviderOptions ): Observable<ISearchResultItem[]> {
        const allProviders = flatten( providers.map( provider => this.searchProviders[ provider ]));

        // FIXME - Waiting for all the search results are completed and emit
        // the final results, if a particular provider takes a long time to load
        // all the results are delayed.. can improve later
        return combineLatest(
            ...allProviders.map( provider => provider.search( text, options )),
        ).pipe(
            map( results => flatten( results )),
        );
    }

}

/**
 * ISearchProvider
 * The interface for search providers
 */
export interface ISearchProvider {
    /**
     * The name of the search provider
     */
    type: SearchResultItemType;

    /**
     * Search and returns an array of ISearchResultItem
     * for the given providers
     * @param text The quary to test
     */
    search( text: string | any, options: ISearchProviderOptions ): Observable<ISearchResultItem[]>;
}

/**
 * Options to be passed when searching
 * These options will be passed on to fuzzysort
 */
export interface ISearchProviderOptions {

    /**
     * For when targets are objects
     * Object keys will be used as keys
     */
    keys: string[];

    /**
     * Threshold value for search score
     * Don't return matches worse than this (higher is faster)
     * Lower the value worse search results
     * If no threshold is passed in, results with any matching criteria will return
     */
    threshold?: number;

    /**
     * Limits number of results that search will return
     * When no limit has been specified, whatever the number of matched results will be returned
     */
    limit?: number;

    allowTypo?: boolean;

}


/**
 * When searching, it always should be one of the following type of a search
 */
type SearchResultItemType = 'shape' | 'image' | 'libs' | 'document' | 'edata';

/**
 * ISearchResultItem
 * The interface for search result item
 */
export interface ISearchResultItem {
    /**
     * The id to deferenciate the item
     */
    id: string;

     /**
      * ..
      */
    type: SearchResultItemType;

    /**
     * exact match returns a score of 0. lower is worse
     */
    score?: number;
}
