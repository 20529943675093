import { AbstractCommand, Command, StateService, ModalController, ResourceLoader } from 'flux-core';
import { Injectable } from '@angular/core';
import { ModelSubscriptionManager, SubscriptionStatus } from 'flux-subscription';
import { Observable } from 'rxjs';
import { cloneDeep } from 'lodash';
import { DiagramSub } from '../../../base/diagram/subscription/diagram.sub';
import { DataStore } from 'flux-store';
import { switchMap, filter, take, map, tap } from 'rxjs/operators';
import { DiagramModel } from '../../../base/diagram/model/diagram.mdl';
import { DiagramInfoModel, DiagramSnapshotService } from 'flux-diagram';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { diagramTemplatesItems } from '../../../template/diagram-template-items';
import { IDiagramTemplateItem } from '../../../creator/creator-template-loader';
import { Authentication, PlanPermManager } from 'flux-user';
import { fromPromise } from 'rxjs/internal-compatibility';

/**
 * This command fetches data from a template so that a new
 * diagram can be created based off the template data.
 *
 * This command will only fetch data if a templateId is set.
 * If the user does not have access to the diagram the template is based
 * on, they will be redirected to an error page.
 * @author  Ramishka
 * @since   2019-02-28
 */
@Injectable()
@Command()
export class GetTemplateData extends AbstractCommand {

    constructor( protected modelSubManager: ModelSubscriptionManager,
                 protected dataStore: DataStore,
                 protected snapshotService: DiagramSnapshotService,
                 protected router: Router,
                 protected authService: Authentication,
                 protected state: StateService<any, any>,
                 protected modalController: ModalController,
                 protected resourceLoader: ResourceLoader,
                 protected planPermManager: PlanPermManager ) {
        super()/* istanbul ignore next */;
    }

    /**
     * Retrieves data for template and sets to result data.
     * Navigates to error page in the event of template fetching error.
     */
    public execute(): Observable<any> {
        if ( !this.data || !this.data.templateId ) {
            return;
        }
        const templateId = this.data.templateId;
        this.resultData = {};
        if ( templateId.includes( '_' )) {
            const [ diagramId, snapshotId ] = templateId.split( '_' );
            return this.snapshotService.fetchSnapshot( diagramId, snapshotId ).pipe(
                tap( snapshot => {
                    this.resultData.templateData = snapshot;
                }),
            );
        }
        return this.isDiagramInStorage( templateId ).pipe(
            switchMap( diagramExisted => this.startDiagramSubscription( templateId ).pipe(
                switchMap( subStatus => {
                    if ( subStatus.subStatus !== SubscriptionStatus.errored ) {
                        return this.getDiagram( templateId ).pipe(
                            switchMap(( diagramData: any ) => {
                                this.resultData.templateData = diagramData;
                                // NOTE: Updating the template diagram type to correct one here.
                                // Example: Template diagram can be drawn in block diagram, but actual
                                // type supposed to be flowchart. When creating the diagram from template
                                // it will pick flow chart type mentioned in diagram template collection,
                                // instead of block diagram type.
                                const diagramType = diagramData.type || this.getGivenDiagramType( diagramData.id );
                                if ( diagramType ) {
                                    this.resultData.templateData.type = diagramType;
                                }
                                return fromPromise( this.resourceLoader.getDiagramDefsDataByType( diagramType )).pipe(
                                    map(( def: any ) => {
                                        if ( def?.libraries && def.libraries.length ) {
                                          this.resultData.templateData.libraries = cloneDeep( def.libraries );
                                          this.resultData.templateData.libraries[0].userId =
                                            this.authService.currentUserId;
                                        } else {
                                            const libraries = this.getDiagramlibraries( diagramData );
                                            if ( libraries ) {
                                                this.resultData.templateData.libraries = libraries;
                                            }
                                        }
                                        return diagramData;
                                    }),
                                );
                            }),
                            tap({
                                next: diagramData => {
                                    this.resultData.templateData.templateDef = {
                                        id: diagramData.id,
                                        name: diagramData.name,
                                        promptId: diagramData.promptId,
                                    };
                                    // Add 'isPremium' property to templateDef if it's a premium template
                                    if ( this.resultData.templateData.template?.isPremium ) {
                                        this.resultData.templateData.templateDef.isPremium = true;
                                    }
                                },
                                complete: () => {
                                    if ( !diagramExisted ) {
                                        this.dataStore.remove( DiagramInfoModel, { id: templateId });
                                    }
                                },
                            }),
                        );
                    } else {
                        this.navigateToError( templateId, subStatus.error );
                        return throwError( new Error( 'There was an error fetching template data' ));
                    }
                }),
            )),
        );
    }

    /**
     * Checks if a given diagram is in the local storage
     * @param diagramId - diagram id to check
     * @return observable which emits true if diagram exists
     */
    protected isDiagramInStorage( diagramId: string ): Observable<boolean> {
        return this.getDiagram( diagramId ).pipe(
            map( diagram => !!diagram ),
        );
    }

    /**
     * Starts the diagram subscription for a given diagram.
     * @return observable which emits the status of subscription
     */
    protected startDiagramSubscription( diagramId: string ): Observable<any> {
        return this.modelSubManager.start( DiagramSub, diagramId ).pipe(
            switchMap( sub => sub.status ),
            filter( statusSubject => statusSubject.subStatus !== SubscriptionStatus.created ),
            take( 1 ),
        );
    }

    /**
     * Navigates to the correct error route when an error occures
     * @param - templateId for which the subscription error occured
     * @param - error that was thrown
     */
    protected navigateToError( templateId: string, error: any ) {
        if ( error.code === 1202 || error.code === 1203 ) {
            this.router.navigateByUrl( templateId + '/view-permission-error' );
        } else {
            this.router.navigateByUrl( templateId + '/diagram-error' );
        }
    }

    /**
     * This will return the diagram type for the given template id
     * from the set of diagram templates mentioned in nucleus.
     * @param diagramId - template id.
     */
    private getGivenDiagramType( diagramId: string ): string {
        const diagramTemplates: Array<IDiagramTemplateItem> = diagramTemplatesItems;
        const template = diagramTemplates.find( i => i.id === diagramId );
        if ( template ) {
            return template.type;
        }
        return null;
    }

    /**
     * Get all diagram libraries and map those into current user and
     * return user libraries, this will add to new diagram model
     * @param diagram diagram data
     */
    private getDiagramlibraries( diagram: DiagramModel ): any[] {
        if ( diagram.libraries && diagram.libraries.length > 0 ) {
            // NOTE: get all user libraries and remove duplicate value
            const libraries = ([].concat( ...diagram.libraries.map( lib => lib.libs )))
                                .filter(( item, index, self ) => self.findIndex( t => ( t[0] === item[0])) === index );

            return [{ userId: this.authService.currentUserId,  libs: libraries }];
        }
        return null;
    }

    /**
     * Fetches a diagram from the local storage
     * @param diagramId
     * @return observable that emits the diagram data once
     */
    private getDiagram( diagramId: string ): Observable<any> {
        return this.dataStore
            .findOneRaw( DiagramModel, { id: diagramId }).pipe(
                take( 1 ),
            );
    }
}

Object.defineProperty( GetTemplateData, 'name', {
    value: 'GetTemplateData',
});
