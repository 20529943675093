import { DiagramCommandEvent } from './../../../diagram/command/diagram-command-event';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { EDataManage } from '../../../../framework/edata/edata-manage.svc';
import {
    CollapsibleMenu, CollapsibleMenuItem, CommandService,
    DialogBoxController,
    EventIdentifier,
    ModalController, Random, StateService, Tracker, NotifierController,
} from 'flux-core';
import { isEqual as _isEqual } from 'lodash';
import { BehaviorSubject, combineLatest, Subscription, Observable, of, Subject, fromEvent } from 'rxjs';
import {
    distinctUntilChanged,
    filter, map, switchMap, debounceTime,
    tap, last, switchMapTo, take, skip,
} from 'rxjs/operators';
import { DiagramLocatorLocator } from '../../../../base/diagram/locator/diagram-locator-locator';
import { EDataLocatorLocator } from '../../../../base/edata/locator/edata-locator-locator';
import { EDataModel } from '../../../../base/edata/model/edata.mdl';
import { EDataLibraryPanel } from './edata-library-panel.cmp';
import { PlanPermManager, UpgradeDialogType, UpgradeDialogWindow, UserLocator } from 'flux-user';
import { IDialogBoxData, PlanPermission } from 'flux-definition';
import { CollaboratorType, ProjectLocator } from 'flux-diagram';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { SidebarSearch } from '../../../../framework/ui/search/sidebar-search.cmp';
import { EventCollector } from 'flux-core';
import { EDataCommandEvent } from 'apps/nucleus/src/base/edata/command/edata-command-event';
import { TranslateService } from '@ngx-translate/core';
import { DataStore } from 'flux-store';
import { ModelSubscriptionManager } from 'flux-subscription';
import { EDataSub } from 'apps/nucleus/src/base/edata/edata.sub';
import { AbstractDropDown, DynamicComponentService } from 'flux-core/src/ui';
import { LinkDataSourceWindow } from './link-data-source/link-data-source-window.cmp';
import { DataSourceDomain } from '../../data-sources/model/data-source.model';
import { SetupDatabaseDialog } from '../../../view/setup-database-dialog/setup-database-dialog.cmp';
import { EDataService } from 'apps/nucleus/src/base/edata/edata.svc';
import { Notifications } from 'apps/nucleus/src/base/notifications/notification-messages';

// TODO Implement this component instead of LibraryContainer and delete Library container
// tslint:disable-next-line: max-line-length

export enum SETUP_DATABASE_STATE {
    /**
     * sets the setup database panel to expanded state
     */
    EXPANDED = 'expanded',
    /**
     * sets the setup database panel to collapsed state
     */
    COLLAPSED = 'collapsed',
    /**
     * sets the setup database panel to hidden
     */
    HIDDEN = 'hidden',
}

// tslint:disable:member-ordering
/**
 * This is the edata container layout component
 * which will contain the edata collapsible menu.
 *
 */

@Component({
    templateUrl: './edata-container.cmp.html',
    selector: 'edata-container',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [ './edata-container.scss' ],
})

export class EDataContainer implements OnInit, OnDestroy {

    public showNoModel: BehaviorSubject<boolean> = new BehaviorSubject( false );
    public showHomeFolder: BehaviorSubject<boolean> = new BehaviorSubject( true );
    public showAllDatabases: BehaviorSubject<boolean> = new BehaviorSubject( true );
    public teamSharedDBs: BehaviorSubject<any[]> = new BehaviorSubject([]);

    /**
     * Array containing all the subscriptions which will later
     * be used to unsubcribe on ngOnDestroy.
     */
     protected subs: Array<any> = [];

    /**
     * Collapsible menu element.
     */
    @ViewChild( 'folderEData', { static: true })
    public projectEDatacollapsbileMenu: CollapsibleMenu;

    /**
     * Collapsible menu element.
     */
    // @ViewChild( 'teamSharedEData', { static: true })
    // public teamSharedEDataCollapsbileMenu: CollapsibleMenu;

    /**
     * Collapsible menu element.
     */
    // @ViewChild( 'integratedEData', { static: true })
    // public integratedEDataCollapsbileMenu: CollapsibleMenu;

    @ViewChild( 'dataSourcePanelContainer', { read: ViewContainerRef, static: true })
    public dataSourcePanelContainer: ViewContainerRef;

    @ViewChild( 'searchResultCtlr', { read: AbstractDropDown, static: true })
    public searchResultCtlr: AbstractDropDown;

    /**
     * Perfect Scrollbar component, this will be used to scroll the library panel.
     */
    @ViewChild( PerfectScrollbarComponent, { static: true }) public directiveScroll: PerfectScrollbarComponent;

    /**
     * Sidebar Search component required to keep track of ongoing searches.
     */
    @ViewChild ( SidebarSearch, { static: true }) public sidebarSearch: SidebarSearch;


    protected titleRenameSubs: {[id: string]: Subscription } = {};
    protected defaultDataSource = 'gihub.com';

    public disconnectedDBs: Observable<{ id: string, name: string, callback: any, type: string }[]>;

    /**
     * If it has global DB which is connected to this diagram.
     * Helps to show the Global DB place holder.
     */
    // public hasLinkedGlobalDB: BehaviorSubject<boolean>;

    public showDisconnectedDbs: BehaviorSubject<boolean> = new BehaviorSubject( false );
    public hasFolderEditorAccess: BehaviorSubject<boolean> = new BehaviorSubject( false );
    public hasCustomDbPermission = false;

    /**
     * Emits whether the edata database list is empty or not.
     */
    public isEdataEmpty: BehaviorSubject<boolean> = new BehaviorSubject( false );

    /**
     * Indicates whether this menu item is premium or not
     */
    public isPremium: boolean = false;

    /**
     * Whether the user is in free or demo plan or not
     */
    public isFreeOrDemoUser: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>( true );

    /**
     * If current user is part of a team.
     */
    public isTeamUser: BehaviorSubject<boolean> = new BehaviorSubject( true );

    public databaseSearchText: BehaviorSubject<string> = new BehaviorSubject( '' );

    public selectedDbName: Subject<string> = new BehaviorSubject( '' );

    public allDbs: BehaviorSubject<any[]> = new BehaviorSubject([]);

    public filteredDbs: Subject<any[]> = new BehaviorSubject([]);
    public filteredDataSources: Subject<any[]> = new BehaviorSubject([]);

    public isExpanded: BehaviorSubject<boolean> = new BehaviorSubject( false );

    public setupDatabaseState: BehaviorSubject<string> = new BehaviorSubject( SETUP_DATABASE_STATE.HIDDEN );

    private dbInstanceCount = 0;

    constructor( protected state: StateService<any, any>,
                 protected eDataManage: EDataManage,
                 protected modalController: ModalController,
                 protected commandService: CommandService,
                 protected ll: DiagramLocatorLocator,
                 protected ell: EDataLocatorLocator,
                 protected permManager: PlanPermManager,
                 protected projectLocator: ProjectLocator,
                 protected userLocator: UserLocator,
                 protected translate: TranslateService,
                 protected dialogBoxController: DialogBoxController,
                 protected dataStore: DataStore,
                 protected subscriptionManager: ModelSubscriptionManager,
                 private dynamicComponentService: DynamicComponentService,
                 private edataService: EDataService,
                 private notifierController: NotifierController,
                  ) {
        // super( state, modalController, commandService, ell, permManager );
        this.disconnectedDBs = this.getDisconnectedDbsObservable();
        this.hasCustomDbPermission = permManager.check([ PlanPermission.CUSTOM_DATABASES ]);
        this.updateDiagramEDataExists();
        this.permManager.isFreeOrDemoUser().subscribe( isFree => this.isFreeOrDemoUser.next( isFree ));
    }

    /**
     * Gets the libraries to load from the state and prepare the UI.
     */
    public ngOnInit() {
        const sub = combineLatest([
            this.getProjectCollabStatus(),
            this.getCurrentDiagramEDataModels(),
            this.listenToDatabaseStateChnges(),
            // dataSourcePanelsObs,
            // this.ll.forCurrentObserver( false ).pipe(
            //     switchMap( locator => locator.getDiagramModel()),
            //     map( diagram => diagram.project?.id || diagram.project ),
            //     distinctUntilChanged(),
            // ),
        ]).subscribe(([[ isFolderOwner, isFolderEditor ], eDataModels/* , projectId */ ]) => {
            this.hasFolderEditorAccess.next( isFolderEditor );
            if ( eDataModels.length === 0 ) {
                this.showNoModel.next( true );
            } else {
                this.showNoModel.next( false );
            }
            this.dbInstanceCount = eDataModels.length || 0;
            // this.hasLinkedGlobalDB.next( eDataModels.some( val =>
            //     val.getProjectId() && val.getProjectId() !== projectId ));
            this.updateEDataMenu( this.projectEDatacollapsbileMenu, eDataModels,
                                    'folder', isFolderOwner, isFolderEditor );
            // if ( isTeamUser ) {
            //     this.updateEDataMenu( this.teamSharedEDataCollapsbileMenu, linkedTeamShared, 'organisation' );
            // }
        }, err => {
            this.updateEDataMenu( this.projectEDatacollapsbileMenu, [], 'folder' );
        });
        this.subs.push( sub );
        this.subs.push( this.state.changes( 'SearchDSPanel' ).pipe(
            filter( dsId => !!dsId ),
        ).subscribe( dsId => {
            this.searchIntegration( dsId );
        }));

        const sub2 = this.state.changes( 'CurrentProject' ).subscribe( val => {
            if ( val === 'home' ) {
                this.showHomeFolder.next( true );
                this.showNoModel.next( false );
            } else {
                this.showHomeFolder.next( false );
            }
        });
        this.subs.push( sub2 );
        this.subs.push( this.state.changes( 'CurrentDiagram' ).pipe(
            distinctUntilChanged(),
        ).subscribe(() => {
            if ( this.dataSourcePanelContainer && this.dataSourcePanelContainer.length > 0 ) {
                this.dataSourcePanelContainer.clear();
            }
            this.showAllDatabases.next( false );
            this.showDisconnectedDbs.next( false );
        }),
        this.state.changes( 'CurrentDiagram' ).pipe(
            skip( 1 ),
        ).subscribe(() => this.state.set( 'SetupDatabaseState', SETUP_DATABASE_STATE.HIDDEN )));
        this.fetchTeamSharedDBs();
        this.trackChildExpandEvents();

        this.subs.push(
            this.getAllDatabases().subscribe(),
        );
    }

    protected trackChildExpandEvents() {
        this.subs.push( this.projectEDatacollapsbileMenu.childToggled.subscribe(() =>
                                            Tracker.track( 'database.folder.click' )));
        // this.subs.push( this.teamSharedEDataCollapsbileMenu.childToggled.subscribe(() =>
        //                                     Tracker.track( 'database.organisation.click' )));
        // this.subs.push( this.integratedEDataCollapsbileMenu.childToggled.subscribe(() =>
        //                                     Tracker.track( 'database.integration.click' )));
    }

    protected getCurrentDiagramEDataModels(): Observable<EDataModel[]> {
        return this.ll.forCurrentObserver( false ).pipe(
            switchMap( locator => locator.getDiagramEData()),
            switchMap( eDataList => {
                if ( !eDataList || eDataList.length === 0 ) {
                    return of([]);
                }
                const eSubs: Observable<EDataModel>[] = [];
                eDataList.forEach( eId => {
                    const eSub = this.ell.getEData( eId ).pipe(
                        switchMap( eLocator => eLocator.getEDataModel()));
                    eSubs.push( eSub );
                });
                return combineLatest( eSubs );
            }),
        );
    }

    protected getProjectCollabStatus(): Observable<boolean[]> {
        return combineLatest([
            this.userLocator.getUserData(),
            this.projectLocator.getCurrentProjectObservable().pipe(
                filter( project => !!project && project.id !== 'home' ),
            ),
        ]).pipe(
            map(([ userMdl, projectMdl ]) => {
                const collab = projectMdl.collabs.find( colb => colb.id === userMdl.id );
                let isEditor = false;
                if ( !!collab ) {
                    const isOwner = collab?.role === CollaboratorType.OWNER ;
                    isEditor = isOwner || collab?.role === CollaboratorType.EDITOR;
                    return [ isOwner, isEditor ];
                } else if ( !!userMdl.team ) {
                    isEditor = !!( projectMdl.teamShare.find( t => t.teamId === userMdl.team.id ));
                }
                return [ false, isEditor ];
            }),
            distinctUntilChanged( _isEqual ),
        );
    }

    protected showUpgradeDialog() {

        const dialogData = {
            id: PlanPermission.CREATELY_PREMIUM_LIBRARIES,
            iframeType: UpgradeDialogType.PremiumShape,
            buttons: [
                {
                    type: 'upgrade',
                    clickHandler: () => {},
                },
            ],
            integrationContext: {
                embedded: this.state.get( 'ApplicationIsEmbedded' ),
                environment: this.state.get( 'PluginApp' ),
            },
        } as IDialogBoxData;

        this.modalController.show( UpgradeDialogWindow, {
            inputs: {
                dialogData,
            },
        });
    }

    /**
     * Unsubscribe from all subscriptions.
     */
    public ngOnDestroy(): void {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    public setShowAllDatabases( val: boolean ) {
        this.showAllDatabases.next( val );
        if ( val ) {
            Tracker.track( 'database.showmore.click', { value1Type: 'link-click', value1: 'show all databases' });
        }
    }

    public setDisconnectedDBs( val: boolean ) {
        this.showDisconnectedDbs.next( val );
        if ( val ) {
            Tracker.track( 'database.showmore.click', { value1Type: 'link-click', value1: 'show more' });
        }
    }

    public fetchTeamSharedDBs() {

        this.subs.push(
            this.showAllDatabases.pipe(
                filter( show => show ),
                take( 1 ),
                switchMap(() => combineLatest([
                    this.commandService.dispatch( EDataCommandEvent.getAllTeamSharedEDataInfo ).pipe( last()),
                    this.ell.currentEDataModels().pipe(
                        map( eMdls => !!eMdls ? eMdls.map( eMdl => eMdl.getId()) : []),
                        distinctUntilChanged( _isEqual ),
                    ),
                ])),
                map(([ response, diagramEdataIds ]) => {
                    if ( response.resultData[ 0 ] && response.resultData[ 0 ].length > 0 ) {
                        if ( diagramEdataIds && diagramEdataIds.length > 0 ) {
                            return ( response.resultData[ 0 ] as any[] || [])
                                        .filter( mdl => !diagramEdataIds.includes( mdl.id ));
                        }
                        return response.resultData[ 0 ];
                    }
                    return [];
                }),
                tap( eDataInfos => {
                    let teamDb = [];
                    if ( eDataInfos && eDataInfos.length > 0 ) {
                        teamDb = eDataInfos.map(( e: any ) => ({
                            id: e.id,
                            name: e.name,
                            type: 'team',
                        }));
                    }
                    this.teamSharedDBs.next( teamDb || []);
                }),
            ).subscribe(),
        );
    }

    /**
     * Empty state message title
     */
    public get emptyStateTitle(): string {
        return 'EDATA_PANEL.EMPTY_SCREEN.TITLE';
    }

    /**
     * Empty state message body
     */
    public get emptyStateBody(): string[] {
        if ( this.isFreeOrDemoUser.value ) {
            return [ 'EDATA_PANEL.EMPTY_SCREEN.BODY.LINE_5' ];
        } else {
            return [ 'EDATA_PANEL.EMPTY_SCREEN.BODY.LINE_1', 'EDATA_PANEL.EMPTY_SCREEN.BODY.LINE_2' ];
        }
    }

    /**
     * Empty state message icon
     */
    public get emptyStateIcon(): string {
        return './assets/images/database-panel/database-empty-state.svg';
    }

    public get linkedTeamSharedDBs(): Observable<any[]> {
        // TODO: execute get all linked global dbs.
        return of([{ id: 'bbb', name: 'BBB' }]);
    }

    public linkEDataToDiagram( eDataId: string, section: string = 'folder' ) {
        this.commandService.dispatch( DiagramCommandEvent.addEDataModel, {
            eDataModelId: eDataId,
        });
        if ( section !== 'folder' ) {
            this.subscriptionManager.start( EDataSub, eDataId ).subscribe();
        }
        Tracker.track( `database.${section}.link.click` );
    }

    public onDatabaseSearchTextChange( searchText: string ) {
        this.dataSourcePanelContainer.clear();
        this.showAllDatabases.next( true );
        this.databaseSearchText.next( searchText );
    }

    public onSearchInProgress( inProgress: boolean ) {
        if ( inProgress ) {
            this.searchResultCtlr.open();
        } else {
            fromEvent( document, 'mouseup' ).pipe(
                take( 1 ),
                tap(() => this.searchResultCtlr.close()),
            ).subscribe();
        }
    }

    public searchIntegration( dataSourceId: string ) {
        const dataSource = this.state.get( 'DataSources' ).find( ds => ds.id === dataSourceId );
        const compRef = this.getDataSourcePanel( dataSource );
        if ( compRef ) {
            this.dataSourcePanelContainer.clear();
            compRef.instance.closed.pipe(
                take( 1 ),
            ).subscribe(() => {
                this.dynamicComponentService.remove( this.dataSourcePanelContainer, compRef );
            });
            this.dynamicComponentService.insert( this.dataSourcePanelContainer, compRef );
            compRef.changeDetectorRef.detectChanges();
            this.selectedDbName.next( dataSource.name );
        }
    }

    public showIntegrationsDialog() {
        this.modalController.show( LinkDataSourceWindow ).subscribe();
    }

    public newDatabase() {
        const eDataId = Random.eDataId();
        this.eDataManage.createCustomDB( eDataId, `New Database ${++this.dbInstanceCount}` ).subscribe();
    }

    public toggleSetDatabase() {
        if ( this.setupDatabaseState.value === SETUP_DATABASE_STATE.EXPANDED ) {
            this.setupDatabaseState.next( SETUP_DATABASE_STATE.COLLAPSED );
        } else {
            this.setupDatabaseState.next( SETUP_DATABASE_STATE.EXPANDED );
        }
    }

    public setupDatabase() {
        this.notifierController.hide( Notifications.SETUP_DATABASE );
        this.openDialog().subscribe();
    }

    protected getDataSourcePanel( dataSource: any ) {
        const compType = dataSource.type;
        return this.dynamicComponentService.makeComponent( compType );
    }

    protected updateDiagramEDataExists() {
        this.subs.push( this.ll.forCurrentObserver( false ).pipe(
            switchMap( l => l.getDiagramEData()),
            map( edataList => ( edataList as any )?.length || 0 ),
            distinctUntilChanged(),
            tap( len => this.isEdataEmpty.next( len < 1 )),
        ).subscribe());
    }

    protected getDisconnectedDbsObservable() {
        return this.state.changes( 'CurrentDiagram' ).pipe( switchMap(() => this.ell.currentEDataModels().pipe(
            debounceTime( 200 ),
            switchMap( models => this.ll.forCurrentObserver( false ).pipe(
                switchMap( l => l.getDiagramEData()),
                map( edataList => {
                    this.isEdataEmpty.next( edataList?.length < 1 );
                    const disconnectedModels = models
                        .filter( m => !( edataList || []).includes( m.id ))
                        .map( m => ({
                            id: m.id,
                            name: m.name,
                            callback: () => this.commandService.dispatch( DiagramCommandEvent.addEDataModel, {
                                eDataModelId: m.id,
                            }),
                            type: 'folder',
                        }));
                    return disconnectedModels;
                }),
            )),
        )));
    }

    /**
     * Update this view based on the state changes
     */
    protected updateEDataMenu( collapsibleMenu: CollapsibleMenu,
                               mdls: Array<EDataModel>,
                               section: string,
                               isFolderOwner: boolean = false,
                               isFolderEditor: boolean = false ) {
        const eDataMdls = [];
        let otherMdls = [];
        mdls.forEach( mdl => {
            ( mdl instanceof EDataModel ? eDataMdls : otherMdls ).push( mdl );
        });

        const eDataDefIds = eDataMdls.map( m => m.defId );
        otherMdls = otherMdls.filter( mdl => !eDataDefIds.includes( mdl.type.eDataDefId ));

        // If the user is not folder editor then he should not be able to create an EData model
        // for any inegration( Github ).
        if ( isFolderEditor ) {
            mdls = eDataMdls.concat( otherMdls );
        }
        collapsibleMenu.panels.forEach(( panel, index ) => {
            const match = mdls.find(  lib => lib.id === panel.id );
            if ( match ) {
                ( match as any ).index = index;
            }
            if ( !match || match && match.name && match.name !== panel.title ) {
                this.titleRenameSubs[ panel.id ].unsubscribe();
                delete this.titleRenameSubs[ panel.id ];
                collapsibleMenu.detachById( panel.id ).subscribe();
            }
        });

        if ( this.showHomeFolder.value ) {
            this.showHomeFolder.next ( false );
        }
        mdls.forEach(( mdl: any ) => {
            if ( mdl instanceof EDataModel ) {
                if ( !collapsibleMenu.has( mdl.id ) && this.permManager.check([ PlanPermission.CUSTOM_DATABASES ])) {
                    this.addEDataModelTOCollapsibleMenu( collapsibleMenu, mdl, section,
                                                            isFolderOwner );
                }
            }
            // else {
            //     if ( !this.integratedEDataCollapsbileMenu.has( mdl.id )) {
            //         this.integratedEDataCollapsbileMenu
            //         .add( CollapsibleMenuItem, mdl.type, { id: mdl.id, section: 'integration' },
            //             false , { preTitleIcon: mdl.type.dataSourceIcon });
            //     }
            // }
        });
        EventCollector.log({
            message: EventIdentifier.EDATA_PANELS_UPDATED,
            models: mdls,
        });
        collapsibleMenu.panels.map( panel => panel.id )
                                .filter( id => !( mdls.find( mdl => mdl.id === id )))
                                .forEach( id => collapsibleMenu.getMenuItemComponent( id ).removeChild.emit());
    }

    protected addEDataModelTOCollapsibleMenu( collapsbileMenu: CollapsibleMenu,
                                              mdl: EDataModel,
                                              section: string,
                                              isFolderOwner: boolean  ) {
        const name = !mdl.name ? 'Model' : mdl.name;
        const collabsibleMenuItemData: any = { _isOpen: false };
        if ( mdl.dataSource && mdl.dataSource.domain === DataSourceDomain.GoogleSheets ) {
            collabsibleMenuItemData.preTitleIcon = 'google-sheet';
        } else {
            if ( !!mdl.teamAccess?.id ) {
                collabsibleMenuItemData.preTitleIcon = 'org-database';
            } else {
                collabsibleMenuItemData.preTitleIcon = 'database';
            }
        }

        collapsbileMenu.add(
            CollapsibleMenuItem,
            EDataLibraryPanel,
            {
                id: mdl.id,
                eDataModel: mdl,
                title: name,
                titleEditable: true,
                isFolderOwner,
                section,
                highlightSelected: true,
                dataSourcePanelContainer: this.dataSourcePanelContainer,
                selected: () => {
                    this.selectedDbName.next( name );
                },
            },
            false,
            collabsibleMenuItemData,
            ( mdl as any ).index || 0,
        ).subscribe(( item: CollapsibleMenuItem ) => {
            const sub = item.titleChanged.subscribe( title => {
                this.eDataManage.renameDatabase( mdl.id, title );
            });
            this.titleRenameSubs[ mdl.id ] = sub;
        });
    }

    /**
     * Returns an array containing both teamSharedDBs and disconnectedDBs.
     */
    protected getAllDatabases(): Observable<any> {
        return combineLatest([ this.teamSharedDBs, this.disconnectedDBs ]).pipe(
            map(([ teamShared, disconnected ]) => [ ...disconnected, ...teamShared ]),
            tap( dbs => this.allDbs.next( dbs )),
            switchMapTo( this.updateSearchResult()),
        );
    }

    protected updateSearchResult() {
        const dataSources = this.state.get( 'DataSources' );
        return this.databaseSearchText.pipe(
            tap( text => {
                this.filteredDbs.next(
                    this.allDbs.value.filter( db => db.name.toLowerCase().startsWith( text.toLowerCase())),
                );
            }),
            tap( text => {
                this.filteredDataSources.next(
                    dataSources.filter( db => db.name.toLowerCase().startsWith( text.toLowerCase())),
                );
            }),
        );
    }

    protected listenToDatabaseStateChnges() {
        return this.state.changes( 'SetupDatabaseState' ).pipe(
            tap( value => {
                if ( value ) {
                    this.setupDatabaseState.next( value );
                } else {
                    this.setupDatabaseState.next( SETUP_DATABASE_STATE.HIDDEN );
                }
            }),
        );
    }

    private openDialog() {
        return this.state.changes( 'LastAddedEDataCandidateShape' ).pipe(
            filter( defId => !!defId ), // skipping initial empty value
            take( 1 ),
            tap( defId => {
                if ( this.state.get( 'CurrentProject' ) === 'home' ) {
                    return;
                }
                this.edataService.getCandidateEDataDefs( defId ).pipe(
                    tap( defs => {
                        this.modalController.show( SetupDatabaseDialog, {
                            inputs: {
                                defs: defs,
                                shapeDefId: defId,
                            },
                        });
                    }),
                ).subscribe();
            }),
        );
    }
}
